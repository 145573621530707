import React from "react";

const Input = props => {
  const input = (
    <input
      onChange={props.onChange}
      value={props.value}
      type={props.type}
      className="form-control"
      placeholder={props.placeholder}
      disabled={props.isDisabled}
      id={props.id}
    />
  );
  if (props.notInForm) return input;
  return (
    <div className="form-group">
      <label htmlFor={props.id} className="form-label">
        {props.label}
        {props.isRequired && <span className="form-required">{"*"}</span>}
      </label>
      {input}
    </div>
  );
};

export default Input;
