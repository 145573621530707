import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    zIndex: 90,
  },
  container: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "#467fcf",
    zIndex: 100,
  },

  header: {
    height: "91px",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "30px",
    paddingRight: "10px",
    width: 70,
  },

  headerOpen: {
    width: 255,
  },

  headerLogoTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    left: 22,
    transform: "translateX(-100%)",
    transition: "all 0.15s cubic-bezier(0, 0, 0.2, 1)",
    opacity: 0,
  },
  headerLogoOpen: {
    transform: "translateX(0)",
    transition: "all 0.15s cubic-bezier(0, 0, 0.2, 1)",
    opacity: 1,
  },

  headerLogo: {
    width: "34px",
    height: "31px",
  },

  headerTitle: {
    fontFamily: "Noto Sans, sans-serif",
    color: "white",
    fontWeight: "bold",
    fontSize: "18px",
    marginLeft: "10px",
  },

  bars: {
    display: "inline-block",
    position: "absolute",
    right: 18,
    top: 30,
    ":hover": {
      cursor: "pointer",
    },
  },

  bar: {
    display: "block",
    backgroundColor: "white",
    height: "2px",
    width: "24px",
    marginBottom: "7px",
    marginTop: "7px",
    transition: "all 0.25s ease",
  },

  topBar: {
    marginLeft: "5px",
    marginRight: "5px",
    transform: "rotate(0deg)",
    opacity: "1",
  },

  middleBar: {
    marginLeft: "5px",
    marginRight: "5px",
    opacity: 1,
  },

  bottomBar: {
    marginLeft: "5px",
    marginRight: "5px",
    transform: "rotate(0deg)",
  },

  topBarOpen: {
    marginLeft: "17px",
    transform: "rotate(225deg) translateY(-13px)",
  },

  middleBarOpen: {
    marginLeft: "17px",
    opacity: 0,
  },

  bottomBarOpen: {
    marginLeft: "17px",
    transform: "rotate(-225deg) translateY(13px)",
  },

  headerMenuIcon: {
    color: "white",
    fontSize: "35px",
    position: "absolute",
    top: "25px",
    left: "30px",
    ":hover": {
      cursor: "pointer",
    },
  },

  row: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "61px",
    marginBottom: "10px",
    color: "white",
    textDecoration: "none",
    transition: "all 0.3s ease",
    ":hover": {
      cursor: "pointer",
      color: "#3fd48b",
      transition: "all 0.3s ease",
    },
    ":hover a": {
      color: "#3fd48b",
      transition: "all 0.3s ease",
    },
  },

  subline: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "35px",
    marginBottom: "10px",
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.8)",
    transition: "all 0.3s ease",
    ":hover": {
      cursor: "pointer",
      color: "#3fd48b",
      transition: "all 0.3s ease",
    },
  },

  subOpen: {
    maxHeight: "2000px",
  },

  subClose: {
    maxHeight: "0px",
    overflow: "hidden",
  },

  title: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: "14px",
    // fontWeight: 'bold',
    // textTransform: 'uppercase',
    // letterSpacing: '0.1em',
    color: "white",
    textDecoration: "none",
    ":hover": {
      color: "#3fd48b",
    },
  },

  subtitle: {
    fontFamily: "Noto Sans, sans-serif",
    fontSize: "14px",
    fontWeight: "100",
    marginLeft: "35px",
  },

  icon: {
    fontSize: "26px",
    marginRight: "15px",
    marginLeft: "22px",
    marginTop: "3px",
  },

  activeLine: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: "4px",
    backgroundColor: "#3fd48b",
  },

  notActiveLine: {
    width: "0px",
  },

  active: {
    color: "#3fd48b",
  },

  hidden: {
    opacity: 0,
    transition: "opacity 0.15s ease",
  },

  visible: {
    opacity: 1,
    transition: "opacity 0.15s ease",
    transitionDelay: ".15s",
  },

  open: {
    width: 285,
    transition: "all 0.15s cubic-bezier(0, 0, 0.2, 1)",
  },

  close: {
    width: 70,
    transition: "all 0.15s cubic-bezier(0, 0, 0.2, 1)",
  },

  chevron: {
    marginLeft: "auto",
    marginRight: 35,
    fontSize: "18px",
    verticalAlign: "middle",
    top: 3,
    color: "white",
    transition: "all 0.3s ease",
    position: "relative",
    ":hover": {
      ":after": {
        opacity: 1,
      },
    },
    ":after": {
      content: '" "',
      width: 49,
      height: 28,
      borderRadius: "100px",
      backgroundColor: "rgba(255,255,255,0.2)",
      position: "absolute",
      left: "calc(50% - 25px)",
      top: "calc(50% - 17px)",
      transition: "all 0.3s ease",
      opacity: 0,
    },
  },
  // tooltip
  tooltipParent: {
    position: "relative",
  },
  tooltipSibling: {
    position: "relative",
    height: "1em",
    ":hover + div": {
      opacity: 1,
      display: "block",
      left: "120%",
    },
  },
  tooltip: {
    position: "absolute",
    zIndex: 2,
    top: 10,
    left: "150%",
    backgroundColor: "#373737",
    color: "white",
    fontSize: 12,
    padding: "5px 10px",
    borderRadius: 4,
    opacity: 0,
    display: "none",
    transition: "all 0.25s ease",
    whiteSpace: "nowrap",
    ":before": {
      content: "' '",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: "0 7.5px 8px 7.5px",
      borderColor: "transparent transparent #373737 transparent",
      transform: "rotate(-90deg)",
      position: "absolute",
      top: 9,
      left: -10,
    },
  },
});
