import { bindActionCreators } from "redux";

import actions from "../actions";

export function mapDispatchToProps() {
  return dispatch => ({ actions: getBound(actions, dispatch) });
}

function getBound(actions, dispatch) {
  return Object.keys(actions).reduce((acc, curr) => {
    acc[curr] = bindActionCreators(actions[curr], dispatch);
    return acc;
  }, {});
}

export const getNormalizedString = string => {
  return string
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[’']/g, " ");
};

export function formatGEOCoordinates(value) {
  return value.replace(/,/g, ".");
}
