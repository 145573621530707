import React, { Component } from "react";
import { connect } from "react-redux";

import Form from "./form";
import { mapDispatchToProps } from "../../utils";

class Edit extends Component {
  componentWillMount() {
    const { actions, campaignId } = this.props;
    actions.campaign.deleteCurrent();
    if (campaignId) {
      actions.campaign.get(campaignId);
    }
    actions.advertiser.fetchAll();
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.campaign.deleteCurrent();
    actions.global.hideModal();
  }
  onSubmit = values => {
    const { actions, history, campaignId } = this.props;
    if (campaignId) {
      return actions.campaign.update(campaignId, values).then(({ id }) => history.push(`/campaign/${id}`));
    }
    return actions.campaign.create(values).then(({ campaign }) => history.push(`/campaign/${campaign.data.id}`));
  };
  onCancel = () => this.props.history.goBack();
  onAdvertiserSubmit = values => {
    const { actions } = this.props;
    const payload = { ...values };
    actions.advertiser.create(payload).then(_ => {
      actions.advertiser.fetchAll();
      actions.global.hideModal();
    });
  };
  onAdvertiserCancel = () => {
    this.props.actions.global.hideModal();
  };
  onChangeTab = tabKey => {
    this.setState({ activeTab: tabKey });
  };
  render() {
    if (this.props.campaigns.isGetting) return <p>{"Loader"}</p>;
    return (
      <div className="my-9 my-md-9">
        <div className="container">
          <Form
            current={this.props.campaigns.current}
            advertisers={this.props.advertisers.all}
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            onAdvertiserSubmit={this.onAdvertiserSubmit}
            onAdvertiserCancel={this.onAdvertiserCancel}
            global={this.props.global}
            actions={this.props.actions}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ user, routing, campaigns, advertisers, global }) => ({
    user,
    routing,
    campaigns,
    advertisers,
    global,
  }),
  mapDispatchToProps,
)(Edit);
