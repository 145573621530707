import React, { useMemo, useState, useCallback } from "react";

import { Select } from "../../forms";
import RadioPOI from "./radioPOI";
import { useFuseSearch } from "../../../hooks/useFuseSearch";

function AssetSelector({ type, variable, assetsOptions, selectedAssets, setSelectedAssets, onChange }) {
  const isComplete = !!variable.isComplete;

  const { search } = useFuseSearch(assetsOptions);
  const location = variable.value.label;
  const searchResults = useMemo(() => (!isComplete && type === "poi" ? search(location) : []), [location, isComplete, type, search]);

  const initialComponentDisplayed = !isComplete && searchResults.length > 0 ? "radio" : "select";
  const [componentDisplayed, setComponentDisplayed] = useState(initialComponentDisplayed);
  const currentSelectedAsset = selectedAssets.find(asset => asset.value === variable.value);

  const handleChange = useCallback(
    newValue => {
      const assetExists = Boolean(currentSelectedAsset);
      const newAsset = { ...variable, name: newValue === "" ? null : newValue, isComplete: false };
      setSelectedAssets(
        assetExists ? selectedAssets.map(asset => (asset.value === variable.value ? newAsset : asset)) : [...selectedAssets, newAsset],
      );
      onChange(newValue);
    },
    [currentSelectedAsset, onChange, selectedAssets, setSelectedAssets, variable],
  );

  return (
    <>
      {componentDisplayed === "radio" && (
        <RadioPOI
          variable={variable}
          options={assetsOptions}
          search={searchResults}
          selectedAssets={selectedAssets}
          setSelectedAssets={setSelectedAssets}
          onChange={handleChange}
          onCheck={value => {
            setComponentDisplayed("select");
            handleChange(value);
          }}
          onCancelMatch={() => setComponentDisplayed("select")}
        />
      )}
      {componentDisplayed === "select" && (
        <Select
          options={assetsOptions}
          onChange={handleChange}
          value={currentSelectedAsset ? currentSelectedAsset.name : variable.name}
          clearable={true}
          className="mb-0 w-100"
        />
      )}
    </>
  );
}

export default AssetSelector;
