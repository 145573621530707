import { createContext } from "react";

export const initialData = {
  isPlaying: false,
  setIsPlaying: () => {},
  segmentIndex: null,
  setSegmentIndex: () => {},
  rowIndex: null,
  setRowIndex: () => {},
  audioSrc: null,
  setAudioSrc: () => {},
  audioList: [],
  setAudioList: () => {},
};

export const AudioContext = createContext(initialData);
