import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { ChevronUp, ChevronDown } from "react-feather";
import InputTags from "../../components/forms/tags";
import { mapDispatchToProps } from "../../utils";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }
  onChange = type => value => {
    const { onChange } = this.props;
    onChange(type, value);
  };
  onCollapse = () => {
    return this.setState({ collapsed: !this.state.collapsed });
  };
  render() {
    const { values } = this.props;
    return (
      <Fragment>
        <div className={`card ${this.state.collapsed && "card-collapsed"} mt-6`}>
          <div className="card-header" onClick={this.onCollapse} style={{ cursor: "pointer" }}>
            <h3 className="card-title">{"Tracking"}</h3>
            <div className="card-options">
              <a className={"mt-1"}>{!this.state.collapsed ? <ChevronUp /> : <ChevronDown />}</a>
            </div>
          </div>
          <div className="card-body">
            <InputTags label={"Impressions"} onChange={this.onChange("impressions")} value={values.impressions} />
            <InputTags label={"Creative View"} onChange={this.onChange("creativeView")} value={values.creativeView} />
            <InputTags label={"First Quartile"} onChange={this.onChange("firstQuartile")} value={values.firstQuartile} />
            <InputTags label={"Midpoint"} onChange={this.onChange("midpoint")} value={values.midpoint} />
            <InputTags label={"ThirdQuartile"} onChange={this.onChange("thirdQuartile")} value={values.thirdQuartile} />
            <InputTags label={"Complete"} onChange={this.onChange("complete")} value={values.complete} />
            <InputTags label={"Start"} onChange={this.onChange("start")} value={values.start} />
            <InputTags label={"Pause"} onChange={this.onChange("pause")} value={values.pause} />
            <InputTags label={"Resume"} onChange={this.onChange("resume")} value={values.resume} />
            <InputTags label={"Rewind"} onChange={this.onChange("rewind")} value={values.rewind} />
            <InputTags label={"Skip"} onChange={this.onChange("skip")} value={values.skip} />
            <InputTags label={"Mute"} onChange={this.onChange("mute")} value={values.mute} />
            <InputTags label={"Unmute"} onChange={this.onChange("unmute")} value={values.unmute} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  ({ user, routing, campaigns, ads, assets }) => ({
    user,
    routing,
    campaigns,
    ads,
    assets,
  }),
  mapDispatchToProps,
)(Banner);
