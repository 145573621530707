import React, { useEffect, useRef } from "react";
import { useAudioPlayer } from "../../hooks/useAudioPlayer";

export default function AudioPlayer() {
  const { isPlaying, setIsPlaying, audioSrc, setAudioSrc, rowIndex, setRowIndex, segmentIndex, setSegmentIndex } = useAudioPlayer();
  const audio = useRef(null);

  const onEnded = () => {
    setAudioSrc(null);
    setIsPlaying(false);
    setSegmentIndex(null);
    setRowIndex(null);
    audio.current.pause();
    audio.current.currentTime = 0;
  };

  useEffect(() => {
    if (audio.current) {
      if (isPlaying) {
        if (audio.current.src === audioSrc) {
          audio.current.currentTime = 0;
        } else {
          audio.current.pause();
        }
        audio.current.play();
      } else {
        audio.current.pause();
      }
    }
  }, [isPlaying, audio, audioSrc, segmentIndex, rowIndex]);

  useEffect(() => {
    const audioElement = audio.current;
    audioElement.addEventListener("ended", onEnded);
    return () => {
      if (audioElement) {
        audioElement.removeEventListener("ended", onEnded);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <audio src={audioSrc} ref={audio} />;
}
