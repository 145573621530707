/* global localStorage */
import constants from "../../constants";
import { handleErrors } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export function fetchAll() {
  return dispatch => {
    dispatch({ type: constants.FETCHING_PODCAST });
    return fetch(`${API_URL}/podcasts`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => {
        if (response.status >= 400) throw new Error("Bad response from server");
        else return response.json();
      })
      .then(podcasts => dispatch({ type: constants.FETCH_PODCAST, podcasts }))
      .catch(err => {
        console.error(err);
        dispatch({ type: constants.FETCH_PODCAST, podcasts: [] });
      });
  };
}
