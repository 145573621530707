import React, { Component } from "react";
import ReactDOM from "react-dom";
import { X } from "react-feather";
import classNames from "classnames";

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      search: "",
      dropdownPosition: this.calcDropdownPosition(),
    };
    this.containerRef = React.createRef();
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("scroll", this.updateDropdownPosition, true);
    window.addEventListener("resize", this.updateDropdownPosition);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("scroll", this.updateDropdownPosition, true);
    window.removeEventListener("resize", this.updateDropdownPosition);
  }
  onCollapse = event => {
    event.stopPropagation();
    this.setState({ collapsed: !this.state.collapsed }, () => {
      if (this.state.collapsed) {
        this.inputRef.focus();
        this.updateDropdownPosition();
      } else this.inputRef.blur();
    });
  };
  handleClickOutside = event => {
    if (
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target) &&
      document.querySelector(".selectize-dropdown") &&
      !document.querySelector(".selectize-dropdown").contains(event.target)
    ) {
      this.setState({ collapsed: false });
    }
  };
  onSearch = event => {
    this.setState({ search: event.target.value });
    if (this.props.onSearch) this.props.onSearch(event.target.value);
  };
  setRef = node => {
    this.inputRef = node;
  };
  setContainerRef = node => {
    this.containerRef.current = node;
  };
  getOptionSearchValue = option => {
    if (option.searchValue) return option.searchValue;
    if (typeof option.label === "string") return option.label;
    return option.value;
  };
  getOptions = () => {
    const { options, value } = this.props;
    return options.reduce((acc, option) => {
      if (option.value === value) return acc;
      if (this.state.search) {
        const search = this.state.search.toLocaleLowerCase();
        const optionSearchValue = this.getOptionSearchValue(option).toLowerCase();
        if (optionSearchValue.includes(search)) {
          return [...acc, option];
        }
      } else return [...acc, option];
      return acc;
    }, []);
  };
  onChange = value => event => {
    event.stopPropagation();
    this.setState({ collapsed: false, search: "" });
    this.inputRef.blur();
    this.props.onChange(value);
  };
  onClear = event => {
    event.stopPropagation();
    this.setState({ search: "" });
    this.props.onChange("");
  };
  handleDropdownScroll = event => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if ((scrollTop === 0 && event.deltaY < 0) || (scrollTop + clientHeight >= scrollHeight && event.deltaY > 0)) {
      event.preventDefault();
    }
  };
  calcDropdownPosition = () => {
    if (this.containerRef && this.containerRef.current) {
      const rect = this.containerRef.current.getBoundingClientRect();
      return {
        top: `${rect.bottom + window.scrollY}px`,
        left: `${rect.left + window.scrollX}px`,
        width: `${rect.width}px`,
      };
    }
    return {};
  };
  updateDropdownPosition = () => {
    this.setState({
      dropdownPosition: this.calcDropdownPosition(),
    });
  };
  renderDropdown() {
    const options = this.getOptions();
    if (!this.state.collapsed || options.length === 0) return null;
    const dropdown = (
      <div
        className="selectize-dropdown single form-control custom-select"
        style={{
          display: `${this.state.collapsed ? "block" : "none"}`,
          visibility: "visible",
          ...this.state.dropdownPosition,
          zIndex: "999",
          position: "absolute",
        }}
      >
        <div className="selectize-dropdown-content" onScroll={this.handleDropdownScroll}>
          {this.state.collapsed &&
            options.map((option, index) => {
              const style = {};
              if (option.disabled) {
                style.pointerEvents = "none";
                style.color = "graytext";
              }
              return (
                <div
                  className="option d-flex"
                  key={index}
                  onClick={option.disabled ? undefined : this.onChange(option.value)}
                  style={{ ...style }}
                >
                  {option.indicator && (
                    <div
                      style={{
                        backgroundColor: option.indicator,
                        width: "10px",
                        height: "10px",
                        borderRadius: "5px",
                      }}
                    />
                  )}
                  {option.label}
                </div>
              );
            })}
        </div>
      </div>
    );

    return ReactDOM.createPortal(dropdown, document.body);
  }
  render() {
    const { placeholder, disabled = false, clearable = false, className = "" } = this.props;
    const valueOption = this.props.options.find(option => option.value === this.props.value);
    const styleControl = { cursor: "text" };
    const styleInput = { cursor: "text", textAlign: "start" };
    if (disabled) {
      styleControl.pointerEvents = "none";
      styleInput.color = "graytext";
    }
    const selectComponent = (
      <div ref={this.setContainerRef} className="selectize-control form-control custom-select single" style={styleControl}>
        <div
          className={`selectize-input position-relative items full has-options has-items ${this.state.collapsed && "focus input-active dropdown-active"}`}
          onClick={this.onCollapse}
          style={styleInput}
        >
          {valueOption && <div className="item">{valueOption.label}</div>}
          <input
            ref={this.setRef}
            placeholder={!this.props.value ? placeholder || "Please select a value..." : undefined}
            type="text"
            style={{
              cursor: "text",
              width: `${this.props.value ? "25px" : "100%"}`,
              opacity: 1,
              position: "relative",
              left: "0px",
            }}
            onChange={this.onSearch}
            value={this.state.search}
          />
          {clearable && (this.props.value || this.state.search) && (
            <button className="btn btn-sm btn-link d-flex align-items-center text-secondary input-clear" onClick={this.onClear}>
              <X />
            </button>
          )}
        </div>
        {this.renderDropdown()}
      </div>
    );
    if (this.props.notInForm) return selectComponent;
    return (
      <div className={classNames("form-group", className)}>
        {this.props.label && (
          <label htmlFor={this.props.id} className="form-label">
            {this.props.label}
            {this.props.isRequired && <span className="form-required">{"*"}</span>}
          </label>
        )}
        {selectComponent}
      </div>
    );
  }
}

export default Select;
