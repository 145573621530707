import constants from "../../constants";
import * as modifiers from "./modifiers";
import exporter from "../exporter";

const initial = {
  dropdownMenuCollapsed: true,
  user: null,
  isLogged: false,
  showModal: false,
  modalProps: {},
};

const handlers = {
  [constants.TOGGLE_MENU]: modifiers.onToggleMenu,
  [constants.SHOW_MODAL]: modifiers.onShowModal,
  [constants.HIDE_MODAL]: modifiers.onHideModal,
  [constants.USER_CONNECTED]: modifiers.onGetUser,
  [constants.DELETE_USER]: modifiers.onDeleteUser,
  [constants.ON_LOGIN]: modifiers.onLogin,
};

export default exporter(initial, handlers);
