import React, { useMemo, useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";

import { mapDispatchToProps } from "../../utils";
import { SegmentCard, Select } from "../../components";

function AudioCreative({ onChange, values, assets, podcasts }) {
  const typesSelected = values.map(segment => segment.type);

  const usedAssets = useMemo(() => {
    if (!values || values.length === 0) return {};

    const assetCountMap = values
      .flatMap(segment => segment.value.map(({ name }) => name))
      .reduce((acc, assetName) => {
        acc[assetName] = (acc[assetName] || 0) + 1;
        return acc;
      }, {});

    return assetCountMap;
  }, [values]);
  const onDeleteSegment = useCallback(
    key => {
      const keptSegments = values.filter(segment => segment.key !== key);
      const newSegments = keptSegments.map((segment, index) => ({
        ...segment,
        name: segment.type === "default" ? "Default" : `Segment ${index}`,
      }));
      onChange(newSegments);
    },
    [onChange, values],
  );
  const onDeleteVariable = useCallback(
    (name, value) => {
      const newSegments = values.map(segment => {
        if (name === segment.name) {
          let newValues = segment.value.filter(variable => value !== variable.value);
          if (segment.type === "random") {
            newValues = newValues.map((value, index) => ({
              ...value,
              value: `${index + 1}`,
            }));
          }
          return { ...segment, value: newValues };
        }
        return segment;
      });
      return onChange(newSegments);
    },
    [onChange, values],
  );
  const getAssetsOptions = useMemo(
    () => () => {
      const options = assets.all.audios.map(asset => {
        return {
          label: (
            <div className="gap-2 d-flex justify-content-start align-items-bottom">
              {asset.name}
              <div className="ml-1 text-secondary sm">{`(${usedAssets[asset.name] || 0})`}</div>
            </div>
          ),
          searchValue: asset.name,
          value: asset.name,
        };
      });
      return options;
    },
    [assets.all.audios, usedAssets],
  );
  const onChangeAudio = useCallback(
    (name, assetName) => fileName => {
      const newSegments = values.map(segment => {
        segment.value.map(targeting => {
          if (targeting.value === assetName && segment.name === name) {
            targeting.name = fileName;
            if (segment.type === "poi") {
              targeting.isComplete = false;
            }
          }
          return targeting;
        });
        return segment;
      });
      onChange(newSegments);
    },
    [onChange, values],
  );
  const onAddNewData = useCallback(
    name => value => {
      const allPodcasts = podcasts.all || [];
      const newSegments = values.reduce((acc, segment) => {
        if (name === segment.name) {
          if (segment.type === "podcast") {
            const podcast = allPodcasts.find(({ id }) => id === value);
            const display = podcast ? podcast.podcastTitle : undefined;
            segment.value = [...segment.value, { type: segment.type, value, name: "", display }];
          } else if (segment.type === "poi") {
            const existingCoordinates = new Map(segment.value.map(geo => [`${geo.value.lat},${geo.value.lon}`, geo]));
            const newValues = value.filter(geo => !existingCoordinates.has(`${geo.lat},${geo.lon}`));
            const valuesToEdit = value.filter(geo => existingCoordinates.has(`${geo.lat},${geo.lon}`));

            segment.value = segment.value.map(geo => {
              const key = `${geo.value.lat},${geo.value.lon}`;
              const newGeo = valuesToEdit.find(v => `${v.lat},${v.lon}` === key);
              if (newGeo && geo.value.rad !== newGeo.rad) {
                return {
                  ...geo,
                  value: {
                    ...geo.value,
                    rad: newGeo.rad,
                  },
                };
              }
              return geo;
            });

            segment.value = [
              ...segment.value,
              ...newValues.map(geo => ({
                type: segment.type,
                value: {
                  lat: geo.lat,
                  lon: geo.lon,
                  rad: geo.rad,
                  label: geo.label,
                },
                display: geo.label || `${geo.lat} - ${geo.lon} - ${geo.rad}`,
                name: "",
              })),
            ];
          } else if (segment.type === "random") {
            segment.value = [
              ...segment.value,
              {
                type: segment.type,
                value: `${segment.value.length + 1}`,
                name: "",
              },
            ];
          } else {
            segment.value = [...segment.value, { type: segment.type, value, name: "" }];
          }
        }
        return [...acc, segment];
      }, []);
      onChange(newSegments);
    },
    [onChange, values, podcasts.all],
  );
  const onChangeType = useCallback(
    (key, type) => {
      const newSegments = values.reduce((acc, segment, index) => {
        const isRandom = type === "random";
        const isDefault = type === "default";
        if (segment.key === key) {
          const name = isDefault ? "Default" : `Segment ${index}`;
          const newSegment = {
            ...segment,
            name,
            type,
            value: isRandom ? [{ type, value: "1", name: "" }] : [{ type, value: "default", name: "" }],
            dataTable: isRandom ? [] : [["Default", <Select options={getAssetsOptions} onChange={onChangeAudio(name, "Default")} />]],
          };
          return [...acc, newSegment];
        }
        return [...acc, segment];
      }, []);
      onChange(newSegments);
    },
    [getAssetsOptions, onChange, onChangeAudio, values],
  );
  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }
      const items = Array.from(values);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      const newSegments = items.map((segment, index) => {
        return {
          ...segment,
          name: segment.type === "default" ? "Default" : `Segment ${index}`,
        };
      });
      onChange(newSegments);
    },
    [onChange, values],
  );
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {outerProvided => (
          <div ref={outerProvided.innerRef} {...outerProvided.droppableProps}>
            {values &&
              !!values.length &&
              values.map((segment, index) => {
                return (
                  <Draggable key={segment.key} draggableId={segment.key} index={index}>
                    {innerProvided => (
                      <div ref={innerProvided.innerRef} {...innerProvided.draggableProps} className="pb-1">
                        <SegmentCard
                          key={segment.key}
                          segmentKey={segment.key}
                          segmentName={segment.name}
                          variables={segment.value}
                          type={segment.type}
                          onDeleteVariable={onDeleteVariable}
                          getAssetsOptions={getAssetsOptions}
                          onChangeAudio={onChangeAudio}
                          onAddNewData={onAddNewData(segment.name)}
                          onChangeSegmentType={onChangeType}
                          typesSelected={typesSelected}
                          onDeleteSegment={onDeleteSegment}
                          segmentIndex={index}
                          dragHandleProps={innerProvided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
            {outerProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default connect(
  ({ user, routing, campaigns, assets, podcasts }) => ({
    user,
    routing,
    campaigns,
    assets,
    podcasts,
  }),
  mapDispatchToProps,
)(AudioCreative);
