import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Edit, Play, Pause } from "react-feather";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../utils";

import Progress from "../progress";

class CampaignCard extends Component {
  static displayName = "CampaignCard";
  onChangeCampaignStatus = () => {
    const { campaign, onCampaignUpdated } = this.props;
    const updatedCampaign = { ...campaign, active: !campaign.active };
    onCampaignUpdated(updatedCampaign);
  };
  getPercentFlightTime = () => {
    const { campaign } = this.props;
    const from = moment(campaign.start);
    const to = moment(campaign.end);
    const now = moment();
    const nbDays = to.diff(from, "days");
    const nbDaysFromNow = now.diff(from, "days");
    if (!nbDays) return 0;
    return Math.round((nbDaysFromNow / nbDays) * 100);
  };
  formatMetric = (metricName, value) => {
    if (metricName === "cr")
      return `${new Intl.NumberFormat({ minimumFractionDigits: 2, maximumFranctionDigit: 2 }).format(value * 100) || 0}%`;
    return (value && new Intl.NumberFormat().format(value)) || "0";
  };
  render() {
    const { campaign, isMetricsFetching, color, metrics } = this.props;
    return (
      <div className="card">
        <div className={`card-status bg-${color}`} />
        <div className="card-header">
          <Link className="card-title titleCard" to={`/campaign/${campaign.id}`}>
            {campaign.name}
          </Link>
          <div className="card-options">
            {campaign.active ? (
              <Pause className="mt-2" style={{ cursor: "pointer" }} onClick={this.onChangeCampaignStatus} />
            ) : (
              <Play className="mt-2" style={{ cursor: "pointer" }} onClick={this.onChangeCampaignStatus} />
            )}
            <Link to={`/campaign/${campaign.id}/edit`}>
              <Edit className="mt-2" />
            </Link>
          </div>
        </div>
        <div className="card-body">
          {isMetricsFetching ? (
            <div className="dimmer active">
              <div className="loader" />
            </div>
          ) : (
            <div className="d-flex justify-content-around" style={{ minHeight: "55px" }}>
              {metrics ? (
                Object.keys(metrics).map(metricName => {
                  return (
                    <div className="text-center" key={metricName}>
                      <h4>{this.formatMetric(metricName, metrics[metricName])}</h4>
                      <small style={{ textTransform: "uppercase" }} className="text-muted">
                        {metricName}
                      </small>
                    </div>
                  );
                })
              ) : (
                <span className="text-muted align-self-center">{"No metrics"}</span>
              )}
            </div>
          )}
        </div>
        <div className="card-footer">
          <Progress value={this.getPercentFlightTime()} start={campaign.start} end={campaign.end} />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ user, routing, campaigns, advertisers }) => ({
    user,
    routing,
    campaigns,
    advertisers,
  }),
  mapDispatchToProps,
)(CampaignCard);
