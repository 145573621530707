import React from "react";
import { connect } from "react-redux";

import { mapDispatchToProps } from "../../utils";

export default function requireAuthentication(Component) {
  class AuthenticatedComponent extends React.Component {
    static displayName = "AuthenticatedComponent";
    componentDidMount() {
      const { user, actions, history } = this.props;
      if (!user) actions.global.getUser(history);
    }
    render() {
      return <Component {...this.props} />;
    }
  }
  return connect(({ global }) => ({ user: global.user, isLogged: global.isLogged }), mapDispatchToProps)(AuthenticatedComponent);
}
