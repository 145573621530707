export function onFetchAllAdvertisers(state, action) {
  return {
    ...state,
    advertisers: action.advertisers,
    isFetchingAdvertisers: false,
  };
}

export function onFetchingAllAdvertisers(state, action) {
  return { ...state, isFetchingAdvertisers: true };
}

export function onFetchAllOrders(state, action) {
  return {
    ...state,
    orders: action.orders,
    isFetchingOrders: false,
  };
}

export function onFetchingAllOrders(state, action) {
  return { ...state, isFetchingOrders: true };
}

export function onFetchEntities(state, action) {
  return {
    ...state,
    entities: action.entities,
    isFetchingEntities: false,
  };
}

export function onFetchingEntities(state, action) {
  return { ...state, isFetchingEntities: true };
}
