import React, { Component } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";

export default class Table extends Component {
  handlePreviousPage = () => {
    this.props.onPaginationChange();
  };
  handleNextPage = () => {
    const totalPages = Math.ceil(this.props.data.length / this.props.rowsPerPage);
    this.props.onPaginationChange(totalPages);
  };
  render() {
    const { headers, data, title, pagination = false, rowsPerPage = undefined } = this.props;

    const indexOfLastRow = pagination * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = rowsPerPage !== undefined ? data.slice(indexOfFirstRow, indexOfLastRow) : data;

    const totalPages = Math.ceil(data.length / rowsPerPage);

    const isPrevDisabled = pagination === 1;
    const isNextDisabled = pagination === totalPages;

    return (
      <div>
        {title && (
          <div className="card-header">
            <h1 className="card-title">{title}</h1>
          </div>
        )}
        {pagination && (
          <div className="d-flex align-items-middle justify-content-center">
            <button
              className="p-0 m-0 btn btn-sm btn-outline-primary d-flex align-items-center justify-content-center"
              style={{ cursor: isPrevDisabled ? "not-allowed" : "" }}
              onClick={this.handlePreviousPage}
              disabled={isPrevDisabled}
            >
              <ArrowLeft className="w-4" />
            </button>
            <span className="mx-2">
              Page {pagination} of {totalPages}
            </span>
            <button
              className="p-0 m-0 btn btn-sm btn-outline-primary d-flex align-items-center justify-content-center"
              style={{ cursor: isNextDisabled ? "not-allowed" : "" }}
              onClick={this.handleNextPage}
              disabled={isNextDisabled}
            >
              <ArrowRight className="w-4" />
            </button>
          </div>
        )}
        <div className="table-responsive" style={{ overflowX: "visible" }}>
          <table className="table table-hover table-outline table-vcenter text-nowrap card-table">
            <thead>
              <tr>
                {headers.map(header => {
                  if (typeof header === "object") {
                    return (
                      <th key={header.title} className={"text-center"} {...header.props}>
                        {header.title}
                      </th>
                    );
                  }
                  return (
                    <th key={header} className={"text-center"}>
                      {header}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {currentData.map((row, index) => {
                const [key, ...rest] = row;
                return (
                  <tr key={`${key}-${index}`}>
                    {rest.map((elmt, index) => (
                      <td key={headers[index]} className={"text-center"}>
                        {elmt}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {pagination && (
          <div className="my-2 d-flex align-items-middle justify-content-center">
            <button
              className="p-0 m-0 btn btn-sm btn-outline-primary d-flex align-items-center justify-content-center"
              style={{ cursor: isPrevDisabled ? "not-allowed" : "" }}
              onClick={this.handlePreviousPage}
              disabled={isPrevDisabled}
            >
              <ArrowLeft className="w-4" />
            </button>
            <span className="mx-2">
              Page {pagination} of {totalPages}
            </span>
            <button
              className="p-0 m-0 btn btn-sm btn-outline-primary d-flex align-items-center justify-content-center"
              style={{ cursor: isNextDisabled ? "not-allowed" : "" }}
              onClick={this.handleNextPage}
              disabled={isNextDisabled}
            >
              <ArrowRight className="w-4" />
            </button>
          </div>
        )}
      </div>
    );
  }
}
