/* global localStorage */
import constants from "../../constants";
import { handleErrors } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export function fetchAll(campaignId) {
  return dispatch => {
    dispatch({ type: constants.FETCHING_ALL_ADS });
    return fetch(`${API_URL}/ads?campaignId=${campaignId}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(ads => dispatch({ type: constants.FETCH_ALL_ADS, ads }))
      .catch(err => console.error(err));
  };
}

export function create(values) {
  return dispatch => {
    dispatch({ type: constants.CREATING_AD });
    return fetch(`${API_URL}/ads`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify(values),
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(campaign => dispatch({ type: constants.CREATED_AD, campaign }))
      .catch(err => console.error(err));
  };
}

export function get(id) {
  return dispatch => {
    dispatch({ type: constants.GETTING_AD });
    return fetch(`${API_URL}/ads/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(ads => dispatch({ type: constants.GET_AD, ad: ads[0] }))
      .catch(err => console.error(err));
  };
}

export function update(id, values) {
  return dispatch => {
    dispatch({ type: constants.EDITING_AD });
    return fetch(`${API_URL}/ads/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "PUT",
      body: JSON.stringify(values),
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .catch(err => console.error(err));
  };
}

export function deleteCurrent() {
  return { type: constants.DELETE_CURRENT_AD };
}
export function deleteAll() {
  return { type: constants.DELETE_ALL_AD };
}

export function duplicate(id, creativeId) {
  return dispatch => {
    dispatch({ type: constants.DUPLICATING_AD });
    return fetch(`${API_URL}/ads/${id}/duplicate`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ creativeId }),
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => dispatch({ type: constants.DUPLICATE_AD }))
      .catch(err => console.error(err));
  };
}
