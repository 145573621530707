/* global localStorage */

import constants from "../../constants";
import { handleErrors } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export function fetchMetrics(payload, page) {
  return dispatch => {
    dispatch({ type: constants.METRICS_FETCHING, page });
    return fetch(`${API_URL}/metrics`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(metrics => dispatch({ type: constants.METRICS_FETCHED, metrics, page }))
      .catch(err => console.error(err));
  };
}

export function deleteAll() {
  return { type: constants.DELETE_ALL_METRICS };
}
