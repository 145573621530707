import constants from "../../constants";
import * as modifiers from "./modifiers";
import exporter from "../exporter";

const initial = {
  isFetching: false,
  all: null,
};

const handlers = {
  [constants.FETCHING_PODCAST]: modifiers.onFetchingPodcasts,
  [constants.FETCH_PODCAST]: modifiers.onFetchPodcasts,
};

export default exporter(initial, handlers);
