/* global localStorage */
import constants from "../../constants";
import { handleErrors } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export function fetchAll(campaignId) {
  return dispatch => {
    dispatch({ type: constants.FETCHING_ALL_ADVERTISERS });
    return fetch(`${API_URL}/advertisers`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(advertisers => dispatch({ type: constants.FETCH_ALL_ADVERTISERS, advertisers }))
      .catch(err => console.error(err));
  };
}

export function create(values) {
  return dispatch => {
    dispatch({ type: constants.CREATING_ADVERTISER });
    return fetch(`${API_URL}/advertisers`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify(values),
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(advertiser => dispatch({ type: constants.CREATED_ADVERTISER, advertiser }))
      .catch(err => console.error(err));
  };
}

export function get(id) {
  return dispatch => {
    dispatch({ type: constants.GETTING_ADVERTISER });
    return fetch(`${API_URL}/advertisers/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(advertisers =>
        dispatch({
          type: constants.GET_ADVERTISER,
          advertiser: advertisers[0],
        }),
      )
      .catch(err => console.error(err));
  };
}

export function update(id, values) {
  return dispatch => {
    dispatch({ type: constants.EDITING_ADVERTISER });
    return fetch(`${API_URL}/advertisers/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "PUT",
      body: JSON.stringify(values),
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .catch(err => console.error(err));
  };
}

export function deleteCurrent() {
  return { type: constants.DELETE_CURRENT_ADVERTISER };
}
