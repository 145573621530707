/* global localStorage */

import constants from "../../constants";
import { handleErrors } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export function fetchAll(campaignId) {
  return dispatch => {
    dispatch({ type: constants.FETCHING_ALL_PUBLISHER_LINES });
    return fetch(`${API_URL}/publisherLine?campaignId=${campaignId}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(publisherLines => dispatch({ type: constants.FETCH_ALL_PUBLISHER_LINES, publisherLines }))
      .catch(err => console.error(err));
  };
}

export function create(values) {
  return dispatch => {
    dispatch({ type: constants.CREATING_PUBLISHER_LINE });
    return fetch(`${API_URL}/publisherLine`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify(values),
    }).then(response => handleErrors(response, dispatch));
  };
}

export function get(id) {
  return dispatch => {
    dispatch({ type: constants.GETTING_PUBLISHER_LINE });
    return fetch(`${API_URL}/publisherLine/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(publisherLines =>
        dispatch({
          type: constants.GET_PUBLISHER_LINE,
          publisherLine: publisherLines[0],
        }),
      )
      .catch(err => console.error(err));
  };
}

export function deleteCurrent() {
  return { type: constants.DELETE_CURRENT_PUBLISHER_LINE };
}

export function update(id, values) {
  return dispatch => {
    dispatch({ type: constants.EDITING_PUBLISHER_LINE });
    return fetch(`${API_URL}/publisherLine/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "PUT",
      body: JSON.stringify(values),
    }).then(response => handleErrors(response, dispatch));
  };
}

export function deleteAll() {
  return { type: constants.DELETE_ALL_PUBLISHER_LINE };
}

export function filterByExternalEntities(ids, platform) {
  return dispatch => {
    dispatch({ type: constants.FILTERING_EXTERNAL_ENTITIES });
    return fetch(`${API_URL}/publisherLine?platform=${platform}&filters=externalEntityIds${encodeURIComponent(`==[${ids.join(",")}]`)}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(filteredExternalEntities =>
        dispatch({
          type: constants.FILTER_EXTERNAL_ENTITIES,
          filteredExternalEntities,
        }),
      )
      .catch(err => console.error(err));
  };
}
