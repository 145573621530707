import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Play, Pause, Edit } from "react-feather";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../utils";
import moment from "moment";

const isAFiniteNumber = num => {
  return num != null && !isNaN(num) && isFinite(num);
};

const formatNumber = (value, option) => {
  const number = isAFiniteNumber(value) ? value : 0;

  return Math.abs(number).toLocaleString(navigator.language, option);
};

class AdCard extends Component {
  static displayName = "AdCard";
  onClick = () => {
    this.props.onClick();
  };
  onChangeState = () => {
    const { ad, actions } = this.props;
    this.props.actions.ad.update(ad.id, { ...ad, active: !ad.active }).then(() => actions.ad.fetchAll(ad.campaignId));
  };
  onDuplicate = () => {
    const { ad, creative = {}, onDuplicate } = this.props;

    onDuplicate(ad.id, creative.id, ad.name);
  };
  formatMetric = (metricName, value) => {
    if (metricName === "cr")
      return (
        (value &&
          new Intl.NumberFormat({
            minimumFractionDigits: 2,
            maximumFranctionDigit: 2,
          }).format(value * 100)) ||
        "0%"
      );
    return (value && new Intl.NumberFormat().format(value)) || "0";
  };
  getColorCard = ad => {
    const now = moment();
    if (moment(ad.start).isAfter(now)) {
      return "blue";
    }
    if (moment(ad.start).isBefore(now) && moment(ad.end).isAfter(now)) {
      if (ad.active) return "green";
      return "yellow";
    }
    return "red";
  };
  render() {
    const { ad, creative, isMetricsFetching, metrics } = this.props;
    const creativelink = creative
      ? `/campaign/${ad.campaignId}/ads/${ad.id}/creative/${creative.id}`
      : `/campaign/${ad.campaignId}/ads/${ad.id}/creative/create`;
    return (
      <div className="card">
        <div className={`card-status bg-${this.getColorCard(ad)}`} />
        <div className="card-header">
          <Link to={`/campaign/${ad.campaignId}/ads/${ad.id}/edit`}>
            <h3 className="card-title">{ad.name}</h3>
          </Link>
          {creative && creative.building ? (
            <span className="badge badge-warning p-2 ml-3">{"Pending"}</span>
          ) : (
            <span className="badge badge-success p-2 ml-3">{"Finished"}</span>
          )}
          <div className="card-options align-items-center">
            {ad.active ? (
              <Pause className="mr-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
            ) : (
              <Play className="mr-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
            )}
            <Link to={`/campaign/${ad.campaignId}/ads/${ad.id}/edit`} className="mt-1">
              <Edit />
            </Link>
            <Link className="btn btn-secondary btn-sm ml-2" to={`/campaign/${ad.campaignId}/ads/${ad.id}/traffic`}>
              {"Traffic"}
            </Link>
            <Link
              className="btn btn-secondary btn-sm ml-2"
              to={`/campaign/${ad.campaignId}/ads/${ad.id}/preview/${creative && creative.id}`}
            >
              {"Preview"}
            </Link>
            <Link className="btn btn-secondary btn-sm ml-2" to={creativelink}>
              {"Creative"}
            </Link>
            <button className="btn btn-secondary btn-sm ml-2" onClick={this.onDuplicate}>
              {"Duplicate"}
            </button>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex justify-content-around cpm" style={{ minHeight: "55px" }}>
            {ad.cpm == null ? (
              <span className="text-muted align-self-center">{"No CPM"}</span>
            ) : (
              <div className="text-center">
                <h4>
                  {formatNumber(ad.cpm, {
                    style: "currency",
                    currencyDisplay: "symbol",
                    currency: "EUR",
                  })}
                </h4>
                <small style={{ textTransform: "uppercase" }} className="text-muted">
                  CPM
                </small>
              </div>
            )}
          </div>
          {isMetricsFetching ? (
            <div className="metrics dimmer active">
              <div className="loader" />
            </div>
          ) : (
            <div className="d-flex justify-content-around metrics" style={{ minHeight: "55px" }}>
              {metrics ? (
                Object.keys(metrics).map(metricName => {
                  return (
                    <div className="text-center" key={metricName}>
                      <h4>{this.formatMetric(metricName, metrics[metricName])}</h4>
                      <small style={{ textTransform: "uppercase" }} className="text-muted">
                        {metricName}
                      </small>
                    </div>
                  );
                })
              ) : (
                <span className="text-muted align-self-center">{"No metrics"}</span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(() => ({}), mapDispatchToProps)(AdCard);
