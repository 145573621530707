import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import ConnectedRouter from "react-router-redux/ConnectedRouter";

import { App, Home, Creative, Login, Advertiser, Page404 } from "./pages";
import EditCampaign from "./pages/campaign/edit";
import ViewCampaign from "./pages/campaign/view";
import Traffic from "./pages/traffic";
import Preview from "./pages/preview";
import EditAd from "./pages/ad/edit";
import EditPublisherLine from "./pages/publisherLine/edit";
import EditAdvertiser from "./pages/advertiser/edit";
import { ScrollToTop, Authenticator, NotSupportedBrowser } from "./components";
import store, { history } from "./store";

import "./styles/main.sass";
import "./index.css";
import "font-awesome/css/font-awesome.min.css";

const target = document.querySelector("#root");
const isChrome = (() => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== "undefined";
  const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
  const isIOSChrome = winNav.userAgent.match("CriOS");

  return (
    isIOSChrome ||
    (isChromium !== null && typeof isChromium !== "undefined" && vendorName === "Google Inc." && isOpera === false && isIEedge === false)
  );
})();

const campaignRender = ({ match: { url, params }, ...rest }) => {
  return (
    <Switch>
      <Route exact path={`${url}/`} render={props => <ViewCampaign {...props} campaignId={params.id} />} />
      <Route path={`${url}/edit`} render={props => <EditCampaign {...props} campaignId={params.id} />} />
      <Route
        path={`${url}/publisherLine/:publisherLineId`}
        render={({ match: { params: publisherLineParams }, ...publisherLineRest }, props) => (
          <EditPublisherLine
            {...props}
            {...publisherLineRest}
            campaignId={params.id}
            publisherLineId={publisherLineParams.publisherLineId}
          />
        )}
      />
      <Route
        path={`${url}/ads/:adId`}
        render={({ match: { url: adUrl, params: adParams }, ...adRest }) => {
          return (
            <Switch>
              <Route
                exact
                path={`${adUrl}/`}
                render={props => <EditAd {...props} {...adRest} campaignId={params.id} adId={adParams.adId} />}
              />
              <Route
                path={`${adUrl}/edit`}
                render={props => <EditAd {...props} {...adRest} campaignId={params.id} adId={adParams.adId} />}
              />
              <Route
                path={`${adUrl}/creative/:id`}
                render={props => <Creative {...props} {...adRest} adId={adParams.adId} campaignId={params.id} />}
              />
              <Route
                path={`${adUrl}/traffic`}
                render={props => <Traffic {...props} {...adRest} adId={adParams.adId} campaignId={params.id} />}
              />
              <Route
                path={`${adUrl}/preview/:creativeId`}
                render={props => <Preview {...props} {...adRest} adId={adParams.adId} campaignId={params.id} />}
              />
              <Redirect from={`${adUrl}/creative`} to={`${url}/`} />
            </Switch>
          );
        }}
      />
    </Switch>
  );
};

render(
  !isChrome ? (
    <NotSupportedBrowser />
  ) : (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ScrollToTop>
          <App>
            <Switch>
              <Route exact path={"/"} component={Authenticator(Home)} />
              <Route path={`/campaign/create`} component={Authenticator(EditCampaign)} />
              <Route path={`/campaign/:id`} component={Authenticator(campaignRender)} />
              <Route path={"/advertiser/:advertiserId"} component={Authenticator(EditAdvertiser)} />
              <Route path={"/advertiser/"} component={Authenticator(Advertiser)} />
              <Route exact path={"/login"} component={Login} />
              <Route component={Page404} />
            </Switch>
          </App>
        </ScrollToTop>
      </ConnectedRouter>
    </Provider>
  ),
  target,
);
