import React from "react";

import { mappingSegment } from "./segmentType";

export default function SegmentFooter({ onAddNewData, variables, type, dataToAdd, setDataToAdd }) {
  const Component = mappingSegment[type] && mappingSegment[type].component;
  const isRandomSegment = type === "random";
  const width = mappingSegment[type] && mappingSegment[type].width;
  const props = mappingSegment[type] && mappingSegment[type].props(variables);

  return (
    <>
      {Component && (
        <div className="card-footer">
          <div className="row align-items-center">
            <div style={{ width: width || "500px" }}>
              <Component {...props} onChange={setDataToAdd} value={dataToAdd} />
            </div>
            <button onClick={() => onAddNewData(dataToAdd)} disabled={!dataToAdd} className="ml-auto btn btn-primary">
              {"Add"}
            </button>
          </div>
        </div>
      )}
      {isRandomSegment && (
        <div className="card-footer">
          <div className="row">
            <button onClick={() => onAddNewData(dataToAdd)} className="ml-auto btn btn-primary">
              {"Add"}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
