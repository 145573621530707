import constants from "../../constants";
import * as modifiers from "./modifiers";
import exporter from "../exporter";

const initial = {
  home: { isFetching: false },
  overview: { isFetching: false },
  ads: { isFetching: false },
};

const handlers = {
  [constants.METRICS_FETCHING]: modifiers.onMetricsFetching,
  [constants.METRICS_FETCHED]: modifiers.onMetricsFetched,
  [constants.DELETE_ALL_METRICS]: modifiers.onDeleteAllMetrics,
};

export default exporter(initial, handlers);
