import React, { Component } from "react";

export default class InputTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      currentValue: "",
      inputWidth: "4px",
    };
  }
  onChangeValue = event => {
    this.setState({ currentValue: event.target.value });
  };
  setRef = name => node => {
    this[name] = node;
  };
  onKeyDown = key => {
    if (key.keyCode === 9) {
      key.preventDefault();
      this.props.onChange([...this.props.value, this.state.currentValue]);
      this.setState({ currentValue: "" });
    }
    if (key.keyCode === 8 && !this.state.currentValue) {
      key.preventDefault();
      this.props.onChange(this.props.value.slice(0, -1));
    }
  };
  onCollapse = event => {
    event.stopPropagation();
    if (this.state.collapsed) return;
    this.setState({ collapsed: true }, () => {
      this.inputRef.focus();
    });
  };
  onBlur = () => {
    this.setState({ collapsed: false });
  };
  render() {
    const { collapsed } = this.state;
    const { label, value = [] } = this.props;
    return (
      <div className="form-group">
        <label className="form-label">{label}</label>
        <div className="selectize-control form-control multi" onKeyDown={this.onKeyDown}>
          <div
            className={`selectize-input items not-full has-options has-items ${collapsed && "focus input-active dropdown-active"}`}
            onClick={this.onCollapse}
            ref={this.setRef("items")}
          >
            {value.map(value => {
              return <div className="item">{value}</div>;
            })}
            <input
              ref={this.setRef("inputRef")}
              type="text"
              onBlur={this.onBlur}
              onChange={this.onChangeValue}
              value={this.state.currentValue}
              style={{
                width: "100%",
                opacity: 1,
                position: "relative",
                left: "0px",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
