import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Plus } from "react-feather";

import { mapDispatchToProps } from "../../utils";
import { PublisherLineCard } from "../../components";

class PublisherLines extends Component {
  static displayName = "PublisherLines";
  componentDidMount() {
    const {
      campaigns: { current },
    } = this.props;
    this.props.actions.publisherLine.fetchAll(current.id);
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.publisherLine.deleteAll();
  }
  render() {
    const {
      publisherLines: { all: allPublisherLines },
    } = this.props;

    return (
      <Fragment>
        <div className="row justify-content-between align-items-center mb-5 pr-3 pl-3">
          <h2 className="mb-5">{"Publisher Lines"}</h2>
          <Link to={`${this.props.routing.location.pathname}/publisherLine/create`} className="btn btn-secondary ml-auto">
            <Plus className="mt-2" />
          </Link>
        </div>
        <div className="row">
          {allPublisherLines.map(publisherLine => {
            return (
              <div className="col-lg-12" key={publisherLine.id}>
                <PublisherLineCard publisherLine={publisherLine} />
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  }
}

export default connect(
  ({ routing, campaigns, publisherLines }) => ({
    routing,
    campaigns,
    publisherLines,
  }),
  mapDispatchToProps,
)(PublisherLines);
