import React, { useEffect, useState } from "react";
import Uppy from "@uppy/core";
import { DashboardModal } from "@uppy/react";
import XHR from "@uppy/xhr-upload";
import "@uppy/core/src/style.scss";
import "@uppy/dashboard/src/style.scss";
import { Upload } from "react-feather";

export const AssetsUploader = ({ actions, campaignId, onComplete }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uppy] = useState(() =>
    new Uppy({
      onBeforeFileAdded: currentFile => {
        // prevent adding file with more than one dot
        return currentFile.name.split(".").length === 2;
      },
      restrictions: {
        allowedFileTypes: ["audio/*", "image/*"],
      },
    }).use(XHR, {
      fieldName: "file",
      endpoint: `${process.env.REACT_APP_API_URL}/assets/upload/${campaignId}`,
      withCredentials: true,
    }),
  );

  useEffect(() => {
    uppy.on("complete", () => {
      onComplete();
    });
  }, [uppy, onComplete]);

  return (
    <div>
      <button
        type="button"
        className="btn btn-secondary"
        style={{ width: 50 }}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <Upload className="mt-1" />
      </button>
      <DashboardModal
        uppy={uppy}
        open={isModalOpen}
        note="⚠️ A file name can contain only one dot."
        proudlyDisplayPoweredByUppy={false}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        closeModalOnClickOutside
        disableThumbnailGenerator
        closeAfterFinish
      />
    </div>
  );
};
