export function onFetchingAssets(state, action) {
  return { ...state, isFetching: true };
}

export function onFetchAssets(state, action) {
  return { ...state, isFetching: false, all: action.assets };
}

export function onDelete(state) {
  return { ...state, all: null };
}
