import React from "react";
import { connect } from "react-redux";

import { mapDispatchToProps } from "../../utils";

const Page404 = props => {
  return (
    <div className="page">
      <div className="page-content">
        <div className="container text-center">
          <div className="display-1 text-muted mb-5">{"404"}</div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ routing }) => ({ routing }), mapDispatchToProps)(Page404);
