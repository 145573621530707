import { useCallback, useMemo } from "react";
import Fuse from "fuse.js";

function mergeAndRemoveDuplicates(exactResults, fuzzyResults) {
  const mergedResults = [...exactResults, ...fuzzyResults];
  const uniqueResults = [];
  const seenLabels = new Set();

  for (const result of mergedResults) {
    const label = result.item.label;
    if (!seenLabels.has(label)) {
      seenLabels.add(label);
      uniqueResults.push(result);
    }
  }

  return uniqueResults;
}

// Fuse initializations for asset matching.
// We combine all exact matches with the 5 first fuzzy matches to get the best of both worlds.
// Regarding the options:
// threshold defines the relevance of the search, 0 being close to perfect and 1 very fuzzy.
// shouldSort sorts the results by relevance.
// ignoreLocation ignores the location of the match within the string.

export function useFuseSearch(options) {
  const [exactFuse, fuzzyFuse] = useMemo(() => {
    const exact = new Fuse(options, { keys: ["searchValue"], threshold: 0.1, shouldSort: true });
    const fuzzy = new Fuse(options, { keys: ["searchValue"], threshold: 0.7, shouldSort: true, ignoreLocation: true });
    return [exact, fuzzy];
  }, [options]);

  const search = useCallback(
    pattern => {
      const location = pattern || "";
      const exactResults = exactFuse.search(location);
      const fuzzyResults = fuzzyFuse.search(location).slice(0, 5);
      return mergeAndRemoveDuplicates(exactResults, fuzzyResults);
    },
    [exactFuse, fuzzyFuse],
  );

  return { search };
}
