import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../utils";
import googleImg from "../../assets/btn_google_signin_light_normal_web@2x.png";

const API_URL = process.env.REACT_APP_API_URL;

class Login extends Component {
  onLogged = () => {
    this.props.actions.onLoggin();
  };
  render() {
    return (
      <div className="container mt-9">
        <div className="row">
          <div className="col col-login mx-auto">
            <div className="text-center mb-6">
              <img src="https://storage.googleapis.com/audion-production-assets/logo/logo.png" alt="Audion" />
            </div>
            <a href={`${API_URL}/login?r=${window.location.origin}`}>
              <img src={googleImg} alt="google login" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ user, routing, campaigns, global }) => ({
    user,
    routing,
    campaigns,
    global,
  }),
  mapDispatchToProps,
)(Login);
