import React from "react";

const pStyle = {
  margin: 0,
};

const NotSupportedBrowser = () => {
  return (
    <div className="container mt-9">
      <div className="row">
        <div className="col col-login mx-auto">
          <div className="text-center mb-6">
            <img src="https://storage.googleapis.com/audion-production-assets/logo/logo.png" alt="Audion" />
          </div>
          <div className="alert alert-danger center">
            <p style={pStyle}>We don't support this browser. Please use Google Chrome.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotSupportedBrowser;
