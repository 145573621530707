export function onFetchAllAdvertisers(state, action) {
  return { ...state, all: action.advertisers, isFetchingAllAdvertisers: false };
}

export function onFetchingAllAdvertisers(state, action) {
  return { ...state, isFetchingAllAdvertisers: true };
}

export function onCreatingAdvertiser(state) {
  return { ...state, isCreating: true };
}

export function onCreatedAdvertiser(state) {
  return { ...state, isCreating: false };
}

export function onGettingAdvertiser(state) {
  return { ...state, isGetting: true };
}

export function onGetAdvertiser(state, action) {
  return { ...state, current: action.advertiser, isGetting: false };
}

export function onDeleteCurrent(state, action) {
  return { ...state, current: null, isGetting: false };
}
