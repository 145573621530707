export function onFetchAllPublisherLines(state, action) {
  return {
    ...state,
    all: action.publisherLines.sort((elmt1, elmt2) => {
      if (elmt1.name < elmt2.name) return -1;
      if (elmt1.name > elmt2.name) return 1;
      return 0;
    }),
    isFetchingAllPublisherLines: false,
  };
}

export function onFetchingAllPublisherLines(state, action) {
  return { ...state, isFetchingAllPublisherLines: true };
}

export function onCreatingPublisherLine(state) {
  return { ...state, isCreating: true };
}

export function onGettingPublisherLine(state) {
  return { ...state, isGetting: true };
}

export function onGetPublisherLine(state, action) {
  return { ...state, current: action.publisherLine, isGetting: false };
}

export function onDeleteCurrentPublisherLine(state, action) {
  return { ...state, current: null };
}

export function onDeleteAllPublisherLines(state, action) {
  return { ...state, all: [] };
}

export function onFilterEntities(state, action) {
  return {
    ...state,
    filteredEntities: action.filteredExternalEntities,
    isFilteringEntities: false,
  };
}

export function onFilteringEntities(state, action) {
  return { ...state, isFilteringEntities: true };
}
