import constants from "../../constants";
import * as modifiers from "./modifiers";
import exporter from "../exporter";

const initial = {
  isCreating: false,
  isFetchingAllAds: false,
  isGetting: false,
  all: [],
};

const handlers = {
  [constants.FETCH_ALL_ADS]: modifiers.onFetchAllAds,
  [constants.FETCHING_ALL_ADS]: modifiers.onFetchingAllAds,
  [constants.CREATED_AD]: modifiers.onCreatedAd,
  [constants.CREATING_AD]: modifiers.onCreatingAd,
  [constants.GETTING_AD]: modifiers.onGettingAd,
  [constants.GET_AD]: modifiers.onGetAd,
  [constants.DELETE_CURRENT_AD]: modifiers.onDeleteCurrentAd,
  [constants.DELETE_ALL_AD]: modifiers.onDeleteAllAds,
  [constants.DUPLICATING_AD]: modifiers.onDuplicatingAd,
  [constants.DUPLICATE_AD]: modifiers.onDuplicateAd,
};

export default exporter(initial, handlers);
