import { routerReducer } from "react-router-redux";
import campaigns from "./campaigns";
import ads from "./ads";
import global from "./global";
import user from "./user";
import assets from "./assets";
import creatives from "./creatives";
import advertisers from "./advertisers";
import metrics from "./metrics";
import publisherLines from "./publisherLines";
import platforms from "./platforms";
import podcasts from "./podcasts";

export const reducers = {
  router: routerReducer,
  campaigns,
  user,
  ads,
  global,
  assets,
  creatives,
  advertisers,
  metrics,
  publisherLines,
  platforms,
  podcasts,
};
