import constants from "../../constants";
import * as modifiers from "./modifiers";
import exporter from "../exporter";

const initial = {
  isCreating: false,
  isFetchingAllPublisherLines: false,
  isGetting: false,
  all: [],
  isFilteringEntities: false,
  filteredEntities: [],
};

const handlers = {
  [constants.FETCH_ALL_PUBLISHER_LINES]: modifiers.onFetchAllPublisherLines,
  [constants.FETCHING_ALL_PUBLISHER_LINES]: modifiers.onFetchingAllPublisherLines,
  [constants.CREATING_PUBLISHER_LINE]: modifiers.onCreatingPublisherLine,
  [constants.GETTING_PUBLISHER_LINE]: modifiers.onGettingPublisherLine,
  [constants.GET_PUBLISHER_LINE]: modifiers.onGetPublisherLine,
  [constants.DELETE_ALL_PUBLISHER_LINE]: modifiers.onDeleteAllPublisherLines,
  [constants.DELETE_CURRENT_PUBLISHER_LINE]: modifiers.onDeleteCurrentPublisherLine,
  [constants.FILTERING_EXTERNAL_ENTITIES]: modifiers.onFilteringEntities,
  [constants.FILTER_EXTERNAL_ENTITIES]: modifiers.onFilterEntities,
};

export default exporter(initial, handlers);
