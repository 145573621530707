import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../utils";

import Select from "./select";

class SelectPodcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      search: "",
    };
  }
  componentWillMount() {
    const { actions } = this.props;
    actions.podcast.fetchAll();
  }
  render() {
    const allPodcasts = this.props.podcasts.all || [];
    const filteredPodcasts = allPodcasts
      .filter(({ id }) => !this.props.values.includes(id))
      .map(({ id, organisationName, podcastTitle }) => ({
        value: id,
        label: "[" + organisationName + "] " + podcastTitle,
      }));
    return (
      <Select
        value={this.props.value}
        options={filteredPodcasts}
        onChange={this.props.onChange}
        notInForm={true}
        placeholder="Select a podcast..."
      />
    );
  }
}

export default connect(({ podcasts }) => ({ podcasts }), mapDispatchToProps)(SelectPodcast);
