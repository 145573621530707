import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Plus, Edit, Play, Pause, ArrowUpCircle } from "react-feather";

import { mapDispatchToProps } from "../../utils";
import AudioCreative from "./audio";
import BannerCreative from "./banner";
import GeneralCreative from "./general";
import TrackingCreative from "./tracking";
import moment from "moment";
import { AudioProvider } from "../../context/audioProvider";
import AudioPlayer from "../../components/player/audioPlayer";

const BUCKET = process.env.REACT_APP_BUCKET;

const validateDates = (startDay, endDay) => {
  const start = new Date(startDay);
  const end = new Date(endDay);
  return start <= end;
};
class Creative extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "audio",
      values: {
        general: { end: "", start: "" },
        banner: { files: [], click: "" },
        audio: [],
        trackings: {
          impressions: [],
          creativeView: [],
          firstQuartile: [],
          midpoint: [],
          thirdQuartile: [],
          complete: [],
          start: [],
          pause: [],
          resume: [],
          rewind: [],
          skip: [],
          mute: [],
          unmute: [],
        },
      },
      canSubmit: false,
      submitting: false,
    };
  }
  componentWillMount() {
    const { campaignId, adId, actions } = this.props;
    const { id } = this.props.match.params;
    actions.campaign.get(campaignId);
    actions.asset.list(campaignId);
    if (id !== "create") actions.creative.get(id);
    actions.ad.get(adId);
  }
  componentWillReceiveProps(next) {
    if (next.creatives.current && !next.creatives.isGetting && next.creatives.current !== this.props.creatives.current) {
      return this.setValues(this.getValues(next.creatives.current));
    }
    if (next.ads.current && !next.ads.isGetting && next.ads.current !== this.props.ads.current && !this.state.values.general.end) {
      return this.setValues({
        ...this.state.values,
        general: {
          end: moment(next.ads.current.end).format("YYYY-MM-DD"),
          start: moment(next.ads.current.start).format("YYYY-MM-DD"),
        },
      });
    }
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.campaign.deleteCurrent();
    actions.asset.deleteCurrent();
    actions.creative.deleteCurrent();
    actions.ad.deleteCurrent();
  }
  getValues = creative => {
    const {
      ads: { current: currentAd },
    } = this.props;
    const end = creative.end || currentAd.end;
    const start = creative.start || currentAd.start;

    return {
      banner: {
        click: creative.companionBanner.click || "",
        files: creative.companionBanner.banners.map(banner => this.getFileName(banner.url)),
      },
      general: {
        start: moment(start).format("YYYY-MM-DD"),
        end: moment(end).format("YYYY-MM-DD"),
      },
      audio: creative.segments.map((segment, index) => {
        const [first] = segment;
        return {
          name: first.type === "default" ? "Default" : `Segment ${index}`,
          type: first.type,
          value: segment.map(({ type, value, display, name }) => ({ type, value, display, name, isComplete: name !== null })),
          key: `${+new Date()}_${index}`,
        };
      }),
      trackings: creative.trackings || this.state.values.trackings,
    };
  };
  getFileName(url) {
    if (!url) return "";
    const path = url.split("/");
    return path[path.length - 1];
  }
  onChangeTab = tabKey => {
    this.setState({ activeTab: tabKey });
  };
  onSubmit = () => {
    const {
      campaigns: { current: currentCampaign },
      ads: { current: currentAd },
      actions,
      history,
      assets: {
        all: { images: allImages },
      },
    } = this.props;
    const {
      values: { audio, banner, trackings, general },
      canSubmit,
    } = this.state;
    const { id } = this.props.match.params;

    const imagesMap = new Map(allImages.map(image => [image.name, image]));
    const click = banner.click ? (banner.click.trim().length ? banner.click : undefined) : undefined;

    if (canSubmit && !this.state.submitting) {
      this.setState({ submitting: true });
      const payload = {
        name: currentAd.name,
        campaignId: currentCampaign.id,
        adId: currentAd.id,
        advertiserId: currentAd.advertiserId,
        segments: audio.map(segmentType => {
          return segmentType.value.map(({ type, value, name, display }) => {
            return { type, value, display, name: name || null };
          });
        }),
        start: general.start,
        end: general.end,
        active: currentAd.active,
        companionBanner: {
          name: currentAd.name,
          banners: banner.files.map(filename => ({
            url: `https://storage.googleapis.com/${BUCKET}/${imagesMap.get(filename).absoluteName}`,
          })),
          click,
        },
        trackings,
      };
      if (id !== "create")
        return actions.creative.update(id, payload).then(() => {
          this.setState({ submitting: false });
          history.push(`/campaign/${currentCampaign.id}`);
        });
      actions.creative.create(payload).then(() => {
        this.setState({ submitting: false });
        history.push(`/campaign/${currentCampaign.id}`);
      });
    }
  };
  onChange = type => (variable, value) => {
    const { values } = this.state;
    return this.setValues({
      ...values,
      [type]: { ...values[type], [variable]: value },
    });
  };
  onChangeTracking = (type, value) => {
    return this.setValues({
      ...this.state.values,
      trackings: { ...this.state.values.trackings, [type]: value },
    });
  };
  onChangeAudio = segments => {
    const { values } = this.state;
    return this.setValues({ ...values, audio: segments });
  };
  onAddSegment = () => {
    const {
      values: { audio },
    } = this.state;
    const index = audio.length;
    const newSegments = [...audio, { name: `Segment ${index}`, value: [], key: `${+new Date()}_${index}` }];
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
    return this.setValues({ ...this.state.values, audio: newSegments });
  };
  onClickHeader = () => {
    const { history } = this.props;
    const { current: currentCampaign } = this.props.campaigns;
    return history.push(`/campaign/${currentCampaign && currentCampaign.id}`);
  };
  setValues = values => {
    return this.setState({ values, canSubmit: this.canSubmit(values) });
  };
  onScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  canSubmit(values = {}) {
    const { audio = [], banner, general } = values;
    let canSubmit = false;

    const isAudioNotEmpty = audio.length;
    const noUndefinedSegments = audio.every(({ value }) => value.length);
    const noEmptySegments = audio.every(({ value, type }) =>
      value.every(({ value, name }) => {
        return type === "poi" && value !== "default" ? true : name;
      }),
    );
    const isBannerValid = Boolean(banner.files.length);
    const validDates = validateDates(general.start, general.end);

    if (!this.state.submitting && isAudioNotEmpty && noUndefinedSegments && noEmptySegments && isBannerValid && validDates) {
      canSubmit = true;
    }

    return canSubmit;
  }
  getColorCard = campaign => {
    if (!campaign) return;
    const now = moment();
    if (moment(campaign.start).isAfter(now)) {
      return "blue";
    }
    if (moment(campaign.start).isBefore(now) && moment(campaign.end).isAfter(now)) {
      if (campaign.active) return "green";
      return "yellow";
    }
    return "red";
  };
  getAdColorCard = ad => {
    if (!ad) return;
    const now = moment();
    if (moment(ad.start).isAfter(now)) {
      return "blue";
    }
    if (moment(ad.start).isBefore(now) && moment(ad.end).isAfter(now)) {
      if (ad.active) return "green";
      return "yellow";
    }
    return "red";
  };
  onChangeState = () => {
    const {
      actions,
      campaigns: { current },
    } = this.props;
    actions.campaign.update(current.id, { ...current, active: !current.active }).then(() => actions.campaign.fetchAll(current.id));
  };
  render() {
    const {
      ads: { current: currentAd },
      campaigns: { current: currentCampaign },
      assets: { all: allAssets },
    } = this.props;
    const submitDisabled = !this.state.canSubmit || this.state.submitting;

    return (
      <div>
        <div className="my-9 my-md-9">
          <div className="container">
            <div className="page-header">
              <div className="card">
                <div className={`card-status bg-${this.getColorCard(currentCampaign)}`} />
                <div className="card-header" style={{ cursor: "pointer" }} onClick={this.onClickHeader}>
                  <h1 className="mt-3 mb-3">{currentCampaign && currentCampaign.name}</h1>
                  <div className="card-options">
                    {currentCampaign && currentCampaign.active ? (
                      <Pause className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
                    ) : (
                      <Play className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
                    )}
                    <Link
                      onClick={e => e.stopPropagation()}
                      to={`/campaign/${currentCampaign && currentCampaign.id}/edit`}
                      className="mt-1"
                    >
                      <Edit />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className={`card-status bg-${this.getColorCard(currentAd)}`} />
                <div className="card-header">
                  <h2 className="mt-3 mb-3">{currentAd && currentAd.name}</h2>
                  <div className="card-options">
                    {currentAd && currentAd.active ? (
                      <Pause className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
                    ) : (
                      <Play className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
                    )}
                    <Link
                      onClick={e => e.stopPropagation()}
                      to={`/campaign/${currentCampaign && currentCampaign.id}/ads/${currentAd && currentAd.id}/edit`}
                      className="mt-1"
                    >
                      <Edit />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="pl-3 pr-3 mb-5 row justify-content-between align-items-center">
              <h2 className="mt-5 mb-5">{`${currentAd && currentAd.name} creative`}</h2>
              <button className="ml-auto btn btn-secondary" onClick={this.onAddSegment}>
                <Plus className="mt-2" />
              </button>
            </div>
            {!currentAd || !currentCampaign || !allAssets ? (
              <h2 style={{ opacity: 0.6 }}>{"Loading..."}</h2>
            ) : (
              <AudioProvider audios={allAssets.audios}>
                <AudioCreative onChange={this.onChangeAudio} values={this.state.values.audio} />
                <BannerCreative onChange={this.onChange("banner")} values={this.state.values.banner} />
                <TrackingCreative onChange={this.onChangeTracking} values={this.state.values.trackings} />
                <GeneralCreative values={this.state.values.general} onChange={this.onChange("general")} />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="btn btn-primary"
                    style={{ cursor: submitDisabled ? "not-allowed" : "pointer" }}
                    disabled={submitDisabled}
                    onClick={this.onSubmit}
                  >
                    {"Submit"}
                  </button>
                </div>
                <button
                  className={`btn btn-outline-primary d-flex align-items-center position-fixed`}
                  style={{ bottom: 50, right: 50 }}
                  onClick={this.onScrollTop}
                >
                  <ArrowUpCircle className="mr-1" />
                  {"Scroll to top"}
                </button>
                <AudioPlayer />
              </AudioProvider>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ user, routing, campaigns, ads, assets, creatives }) => ({
    user,
    routing,
    campaigns,
    ads,
    assets,
    creatives,
  }),
  mapDispatchToProps,
)(Creative);
