import React, { Component, Fragment } from "react";

import Search from "../forms/search";

export default class Tab extends Component {
  static displayName = "Tab";
  onClick = key => () => {
    this.props.onChange(key);
  };
  render() {
    const { activeTab } = this.props;
    return (
      <div className="row align-items-center">
        <div className="col-lg order-lg-first">
          <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
            {this.props.items.map((item, key) => {
              return (
                <li className="nav-item" key={key} onClick={this.onClick(item.key)}>
                  <a className={`nav-link ${item.key === activeTab && "active"}`}>{item.label}</a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}
