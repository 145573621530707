/* global localStorage */

import constants from "../../constants";
import { handleErrors } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export function fetchAllAdvertisers(platform) {
  return dispatch => {
    dispatch({ type: constants.FETCHING_ALL_ADVERTISERS });
    return fetch(`${API_URL}/platforms/advertisers?platform=${platform}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(advertisers => dispatch({ type: constants.FETCH_ALL_ADVERTISERS, advertisers }))
      .catch(err => console.error(err));
  };
}

export function fetchAllOrders(platform, advertiserId) {
  return dispatch => {
    dispatch({ type: constants.FETCHING_ALL_ORDERS });
    // Only used for DV360
    const advertiserQueryParameter = advertiserId ? `&advertiser=${advertiserId}` : "";
    return fetch(`${API_URL}/platforms/orders?platform=${platform}${advertiserQueryParameter}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(orders => dispatch({ type: constants.FETCH_ALL_ORDERS, orders }))
      .catch(err => console.error(err));
  };
}

export function fetchEntities(platform, order, advertiserId) {
  return dispatch => {
    dispatch({ type: constants.FETCHING_ENTITIES });
    // Only used for DV360
    const advertiserQueryParameter = advertiserId ? `&advertiser=${advertiserId}` : "";
    return fetch(`${API_URL}/platforms/entities?platform=${platform}&order=${order}${advertiserQueryParameter}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(entities => dispatch({ type: constants.FETCH_ENTITIES, entities }))
      .catch(err => console.error(err));
  };
}
