import React, { Component, Fragment } from "react";

export default class MetricsCard extends Component {
  formatMetric = (metricName, value) => {
    if (metricName === "cr") return `${new Intl.NumberFormat({ minimumFractionDigits: 2, maximumFranctionDigit: 2 }).format(value * 100)}%`;
    return new Intl.NumberFormat().format(value);
  };
  render() {
    return (
      <div className="card">
        <div className="card-body p-3 text-center">
          {this.props.isLoading ? (
            <div className="dimmer active">
              <div className="loader" />
              <div className="dimmer-content">{"Loading"}</div>
            </div>
          ) : (
            <Fragment>
              <div className="mb-4" />
              <div className="h1 m-0">{this.formatMetric(this.props.label, this.props.value)}</div>
              <div style={{ textTransform: "uppercase" }} className="text-muted mb-4">
                {this.props.label}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}
