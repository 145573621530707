import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Edit, Play, Pause } from "react-feather";
import moment from "moment";

import { Tab, MetricsCard, Modal } from "../../components";
import { mapDispatchToProps } from "../../utils";
import Overview from "./overview";
import Ads from "./ads";
import Assets from "./assets";
import PublisherLines from "./publisherLines";

const mappingColors = {
  ongoing: "green",
  upcoming: "blue",
  finished: "red",
};

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.tabItems = [
      {
        label: "Overview",
        key: "overview",
        render: Overview,
        props: { campaignId: props.campaignId },
      },
      {
        label: "Ads",
        key: "ads",
        render: Ads,
        props: {
          campaignId: props.campaignId,
          onCreate: this.onCreateAd,
          onDuplicate: this.onDuplicateAd,
        },
      },
      {
        label: "Assets",
        key: "assets",
        render: Assets,
        props: { campaignId: props.campaignId },
      },
      {
        label: "Publisher Lines",
        key: "publisherLines",
        render: PublisherLines,
        props: { campaignId: props.campaignId },
      },
    ];
    this.state = {
      activeTab: localStorage.getItem("campaignTab") || "overview",
    };
  }
  componentWillMount() {
    const { campaignId } = this.props;
    this.props.actions.campaign.get(campaignId);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.campaignId !== this.props.campaignId) {
      this.props.actions.campaign.get(nextProps.campaignId);
    }
  }
  onChangeState = () => {
    const {
      actions,
      campaigns: { current },
    } = this.props;
    actions.campaign.update(current.id, { ...current, active: !current.active }).then(() => actions.campaign.get(current.id));
  };
  onSubmit = values => {
    const { actions, history } = this.props;
    return actions.campaign.create(values).then(() => history.push("/"));
  };
  onCancel = () => this.props.history.push("/");
  onChangeTab = tabKey => {
    localStorage.setItem("campaignTab", tabKey);
    this.setState({ activeTab: tabKey });
  };
  onCreateAd = () => {
    const { history } = this.props;
    return history.push("");
  };
  onDuplicateAd = (adId, creativeId, adName) => {
    const {
      campaigns: { current },
      actions,
    } = this.props;

    this.props.actions.global.showModal({
      modalProps: {
        body: `You are about to duplicate <span style="font-weight: 600;font-size: 18px">${adName}</span>`,
        validate: () => {
          actions.ad.duplicate(adId, creativeId).then(() => {
            actions.ad.fetchAll(current.id);
            actions.creative.fetchAll(current.id);
          });
        },
      },
    });
  };
  getColorCard = campaign => {
    const now = moment();
    if (moment(campaign.start).isAfter(now)) {
      return "blue";
    }
    if (moment(campaign.start).isBefore(now) && moment(campaign.end).isAfter(now)) {
      if (campaign.active) return "green";
      return "yellow";
    }
    return "red";
  };
  render() {
    const { current } = this.props.campaigns;
    const { dropdownMenuCollapsed, showModal, modalProps } = this.props.global;
    if (!current) return <p>{"Loading"}</p>;
    const { render: ComponentTab, props: componentProps } = this.tabItems.find(item => item.key === this.state.activeTab);
    return (
      <div>
        <div className={`header ${dropdownMenuCollapsed && "collapse"} d-lg-flex p-0 mt-8`}>
          <div className="container">
            <Tab items={this.tabItems} activeTab={this.state.activeTab} onChange={this.onChangeTab} />
          </div>
        </div>
        <div className="my-9 my-md-9 my-lg-6">
          <div className="container">
            <div className="page-header">
              <div className="card">
                <div className={`card-status bg-${this.getColorCard(current)}`} />
                <div className="card-header">
                  <h1 className="mb-3 mt-3">{current.name}</h1>
                  <div className="card-options">
                    {current.active ? (
                      <Pause className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
                    ) : (
                      <Play className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
                    )}
                    <Link to={`${this.props.match.url}/edit`} className="mt-1">
                      <Edit />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <ComponentTab {...componentProps} campaignId={this.props.campaignId} />
          </div>
        </div>
        <Modal {...modalProps} show={showModal} />
      </div>
    );
  }
}

export default connect(
  ({ user, routing, campaigns, global }) => ({
    user,
    routing,
    campaigns,
    global,
  }),
  mapDispatchToProps,
)(Campaign);
