import React, { Fragment } from "react";
import moment from "moment";

export default props => {
  const color = props.value > 75 ? "bg-red" : "bg-green";
  const label = props.value > 100 ? "Finished" : props.value < 0 ? "Upcoming" : `${props.value}%`;
  const isUpcoming = props.value < 0;
  return (
    <Fragment>
      <div className="clearfix">
        <div className="float-left">
          <strong>{label}</strong>
        </div>
        <div className="float-right">
          <small className="muted">{`${moment(props.start).format("DD/MM/YYYY")} - ${moment(props.end).format("DD/MM/YYYY")}`}</small>
        </div>
      </div>
      <div className="progress progress-xs">
        <div className={`progress-bar ${color}`} style={{ width: `${isUpcoming ? 0 : props.value}%` }} />
      </div>
    </Fragment>
  );
};
