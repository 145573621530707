import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Download } from "react-feather";
import { Link } from "react-router-dom";

import { Table } from "../../components";
import { mapDispatchToProps } from "../../utils";
import { AssetsUploader } from "./AssetsUploader";

class Assets extends Component {
  static displayName = "Assets";
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    };
  }
  componentWillMount() {
    const { actions, campaignId } = this.props;
    return actions.asset.list(campaignId);
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.asset.deleteCurrent();
  }
  getData = () => {
    const {
      assets: { all },
    } = this.props;
    if (!all) return [];
    return Object.keys(all).reduce((acc, type) => {
      const data = all[type]
        ? all[type].map(elmt => {
            // by default browsers will open the media files in new tabs, we want to enforce the download
            const urlParsed = new URL(elmt.publicUrl);
            urlParsed.searchParams.set("response-content-disposition", "attachment");
            const downloadLink = (
              <a className="btn-link" href={`${urlParsed}`} target="_blank" rel="noreferrer noopener" download>
                <Download className="mr-1" style={{ height: 16, width: 16 }} />
              </a>
            );
            return [`row-key-${elmt.name}`, elmt.name, type, downloadLink];
          })
        : [];

      return [...acc, ...data];
    }, []);
  };
  onDownloadAllFiles = e => {
    const {
      assets: { all: assetsToDownload },
    } = this.props;
    e.preventDefault();
    if (assetsToDownload) {
      Object.keys(assetsToDownload).map(type => {
        const assets = assetsToDownload[type];
        assets.forEach(asset => {
          const link = document.createElement("a");
          link.href = asset.publicUrl;
          link.target = "_blank";
          link.rel = "noreferrer noopener";
          link.download = asset.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      });
    }
  };
  handleComplete = () => {
    const { actions, campaignId } = this.props;
    actions.asset.list(campaignId);
  };
  render() {
    const { campaignId } = this.props;
    const data = this.getData();
    const assetsCount = data.length;
    return (
      <Fragment>
        <div className="pl-3 pr-3 mb-5 row align-items-center justify-content-between" style={{ gap: ".75rem" }}>
          <h2 className="mb-0">{`Assets (${assetsCount})`}</h2>
          <AssetsUploader campaignId={campaignId} onComplete={this.handleComplete} onFileAdded={this.handleFileAdded} />
        </div>
        <div className="pl-3 pr-3 row">
          {assetsCount > 0 ? (
            <div className="card">
              <Table headers={["Name", "Type", "Actions"]} data={this.getData()} />
              {assetsCount > 1 && (
                <button className="btn btn-link" onClick={this.onDownloadAllFiles} download>
                  Download all files
                </button>
              )}
            </div>
          ) : (
            <div className="container text-center mt-9">
              <p className="h4 text-muted font-weight-normal">{"No asset, please upload your file(s)..."}</p>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default connect(
  ({ user, routing, assets, campaigns }) => ({
    user,
    routing,
    assets,
    campaigns,
  }),
  mapDispatchToProps,
)(Assets);
