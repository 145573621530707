import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../utils";
import platforms from "../../assets/platforms.json";

const isAFiniteNumber = num => {
  return num != null && !isNaN(num) && isFinite(num);
};

const formatNumber = (value, option) => {
  const number = isAFiniteNumber(value) ? value : 0;

  return Math.abs(number).toLocaleString(navigator.language, option);
};
class PublisherLineCard extends Component {
  static displayName = "PublisherLineCard";

  isCustomPlatform = currentPlatform => !platforms.some(platform => platform.value === currentPlatform);

  render() {
    const { publisherLine } = this.props;

    const isCustomPlatform = this.isCustomPlatform(publisherLine.platform);
    const platform = isCustomPlatform
      ? { label: publisherLine.platform }
      : platforms.find(platform => platform.value === publisherLine.platform);
    const publisher = isCustomPlatform
      ? { label: publisherLine.publisher }
      : platform.publishers && platform.publishers.find(pub => pub.value === publisherLine.publisher);

    return (
      <div className="card">
        <div className="card-header">
          <Link to={`/campaign/${publisherLine.campaignId}/publisherLine/${publisherLine.id}/edit`}>
            <h3 className="card-title">{publisherLine.name}</h3>
          </Link>
          <div className="card-options align-items-center">
            <Link to={`/campaign/${publisherLine.campaignId}/publisherLine/${publisherLine.id}/edit`} className="mt-1">
              <Edit />
            </Link>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex justify-content-around publisher-line-info">
            {publisherLine.cpm == null ? (
              <span className="text-muted align-self-center">{"No CPM"}</span>
            ) : (
              <div className="text-center">
                <h4>
                  {formatNumber(publisherLine.cpm, {
                    style: "currency",
                    currencyDisplay: "symbol",
                    currency: "EUR",
                  })}
                </h4>
                <small className="text-muted text-uppercase">CPM</small>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-around publisher-line-info">
            <div className="text-center">
              <h4>{publisher.label}</h4>
              <small className="text-muted text-uppercase">Publisher</small>
            </div>
          </div>
          <div className="d-flex justify-content-around publisher-line-info" style={{ borderRight: "0px" }}>
            <div className="text-center">
              <h4>{platform.label}</h4>
              <small className="text-muted text-uppercase">Platform</small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(() => ({}), mapDispatchToProps)(PublisherLineCard);
