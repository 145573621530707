import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input, Toggle, Select } from "../../components";
import moment from "moment";
import { Link } from "react-router-dom";

export default class CampaignForm extends Component {
  static displayName = "CampaignForm";
  constructor(props) {
    super(props);
    this.state = {
      values: props.current
        ? {
            ...props.current,
            end: moment(props.current.end).format("YYYY-MM-DD"),
            start: moment(props.current.start).format("YYYY-MM-DD"),
          }
        : {
            name: "",
            active: false,
            start: "",
            end: "",
          },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.current)
      return this.setState({
        values: {
          ...nextProps.current,
          start: moment(nextProps.current.start).format("YYYY-MM-DD"),
          end: moment(nextProps.current.end).format("YYYY-MM-DD"),
        },
      });
  }
  onChange = type => event => {
    if (type === "active") {
      return this.setState({
        values: { ...this.state.values, active: !this.state.values.active },
      });
    }
    if (type === "cpm") {
      const newValues = {
        values: {
          ...this.state.values,
          [type]: event.target.value == "" ? null : event.target.value,
        },
      };
      return this.setState(newValues);
    }
    const newValues = {
      values: { ...this.state.values, [type]: event.target.value },
    };
    return this.setState(newValues);
  };
  onSubmit = () => {
    this.props.onSubmit(this.state.values);
  };
  render() {
    const { campaignId } = this.props;
    const title = this.props.current ? `Edit ${this.props.current.name}` : "Create Ad";
    return (
      <div className="lg-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">{title}</h3>
          </div>
          <div className="card-body">
            <Input label={"Name"} id={"name"} isRequired type="text" value={this.state.values.name} onChange={this.onChange("name")} />
            <Toggle onChange={this.onChange("active")} value={this.state.values.active} label={"Active"} />

            <Input
              label={"Start Date"}
              id={"start"}
              isRequired
              type="date"
              value={this.state.values.start}
              onChange={this.onChange("start")}
            />

            <Input label={"End Date"} id={"end"} isRequired type="date" value={this.state.values.end} onChange={this.onChange("end")} />
          </div>
          <div className="card-footer text-right">
            <div className="d-flex">
              <Link to={`/campaign/${campaignId}`} className="btn btn-link">
                {"Cancel"}
              </Link>
              <button onClick={this.onSubmit} className="btn btn-primary ml-auto">
                {"Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
