/* global localStorage */

import constants from "../../constants";
import { handleErrors } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export function fetchAll(state) {
  return dispatch => {
    dispatch({ type: constants.FETCHING_ALL_CAMPAIGNS });
    return fetch(`${API_URL}/campaigns?${state}=true`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(campaigns => dispatch({ type: constants.FETCH_ALL_CAMPAIGN, campaigns }))
      .catch(err => console.error(err));
  };
}

export function create(values) {
  return dispatch => {
    dispatch({ type: constants.CREATING_CAMPAIGN });
    return fetch(`${API_URL}/campaigns`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify(values),
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(campaign => dispatch({ type: constants.CREATED_CAMPAIGN, campaign }))
      .catch(err => console.error(err));
  };
}

export function get(id) {
  return dispatch => {
    dispatch({ type: constants.GETTING_CAMPAIGN });
    return fetch(`${API_URL}/campaigns/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(campaign => dispatch({ type: constants.GET_CAMPAIGN, campaign: campaign[0] }))
      .catch(err => console.error(err));
  };
}

export function deleteCurrent() {
  return { type: constants.DELETE_CURRENT_CAMPAIGN };
}

export function update(id, values) {
  return dispatch => {
    dispatch({ type: constants.EDITING_CAMPAIGN });
    return (
      fetch(`${API_URL}/campaigns/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(values),
      })
        // .then((response) => handleErrors(response, dispatch))
        .then(response => response.json())
        .catch(err => console.error(err))
    );
  };
}

export function search(name) {
  return dispatch => {
    return fetch(`${API_URL}/campaigns?name=${name}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response))
      .then(response => response.json())
      .then(campaigns => dispatch({ type: constants.SEARCH_CAMPAIGNS, campaigns }))
      .catch(err => console.error(err));
  };
}

export function deleteAll() {
  return { type: constants.DELETE_ALL_CAMPAIGN };
}
