import React, { Component } from "react";
import { connect } from "react-redux";
import { Edit as EditIcon, Play, Pause, Copy } from "react-feather";
import { Link } from "react-router-dom";
import moment from "moment";
import Select from "../../components/forms/select";

import { mapDispatchToProps } from "../../utils";

const API_URL = process.env.REACT_APP_API_URL;
const DCO_VALIDATOR_URL = process.env.REACT_APP_DCO_VALIDATOR_URL;
const DCO_PREVIEW_URL = process.env.REACT_APP_DCO_PREVIEW_URL;

class Traffic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dsp: null,
      type: null,
      values: {},
      banner: "",
      error: false,
    };
  }
  componentWillMount() {
    const { actions, campaignId, adId } = this.props;
    const { creativeId } = this.props.match.params;
    actions.ad.get(adId);
    actions.campaign.get(campaignId);
    actions.creative.get(creativeId);
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.ad.deleteCurrent();
    actions.campaign.deleteCurrent();
    actions.creative.deleteCurrent();
  }
  componentWillReceiveProps(nextProps) {
    const { current: currentCreative } = nextProps.creatives;
    const { values } = this.state;

    if (currentCreative && Object.entries(values).length === 0) {
      const segmentsDefaultValues = {};
      currentCreative.segments.forEach(segment => {
        const segmentDefault = segment[0];
        segmentsDefaultValues[segmentDefault.type] = segmentDefault.value;
      });
      this.setState({ values: segmentsDefaultValues }, () => {
        this.setUrl();
      });
    }
  }
  getColorCard = campaign => {
    if (!campaign) return;
    const now = moment();
    if (moment(campaign.start).isAfter(now)) {
      return "blue";
    }
    if (moment(campaign.start).isBefore(now) && moment(campaign.end).isAfter(now)) {
      if (campaign.active) return "green";
      return "yellow";
    }
    return "red";
  };
  onChangeDSP = value => {
    this.setState({ dsp: value });
  };
  onChangeType = value => {
    this.setState({ type: value });
  };
  onChangeBanner = value => {
    this.setState({ banner: value });
  };
  onClickHeaderCampaign = event => {
    event.stopPropagation();
    const { history, campaignId } = this.props;
    return history.push(`/campaign/${campaignId}`);
  };
  onChange = type => value => {
    return this.setState({ values: { ...this.state.values, [type]: value } }, () => {
      this.setUrl();
    });
  };
  onCopy = id => {
    document.getElementById(`copy-${id}`).select();
    document.execCommand("copy");
    document.getElementById(`dco-copy-tooltip-${id}`).style.opacity = 0;
    document.getElementById(`dco-copy-btn-${id}`).addEventListener("mouseleave", this.resetTooltip.bind(this, id));
  };
  resetTooltip(id) {
    document.getElementById(`dco-copy-btn-${id}`).removeEventListener("mouseleave", this.resetTooltip.bind(this, id));
    document.getElementById(`dco-copy-tooltip-${id}`).style.removeProperty("opacity");
  }
  setUrl = () => {
    const { current: currentCreative } = this.props.creatives;

    this.setState({ urlLoading: true });

    const valuesArray = Object.keys(this.state.values).map(segmentType => {
      const initialValue = this.state.values[segmentType];
      let valueToJoin = initialValue;
      if (segmentType === "podcast") {
        const currentSegment = currentCreative.segments[currentCreative.segmentOrder.indexOf("podcast")];
        const currentPodcast = currentSegment.find(value =>
          initialValue === "default" ? value.value === initialValue : value.display === initialValue,
        );
        const podcastName = currentPodcast.display ? currentPodcast.display : currentPodcast.value;
        valueToJoin = podcastName
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z0-9]+/g, "-")
          .toUpperCase();
      }
      return `${valueToJoin}`.toUpperCase();
    });
    const joinedValues = valuesArray.join("_");

    fetch(`${API_URL}/public/creatives/dco/${currentCreative.dcoValidatorId}/segmentsCombinations?name=${joinedValues}`)
      .then(res => {
        if (!res.ok) {
          throw res.status;
        }
        return res.json();
      })
      .then(res => {
        const { audios } = res;
        if (!audios) throw new Error();

        const mp3Audio = audios.find(audio => audio.extension === "mp3");
        if (!mp3Audio) throw new Error();

        this.setState({
          url: mp3Audio.publicUrl,
          urlLoading: false,
          error: false,
        });
      })
      .catch(() => {
        this.setState({ urlLoading: false, error: true });
      });
  };
  render() {
    const { current: currentCampaign } = this.props.campaigns;
    const { current: currentAd } = this.props.ads;
    const { current: currentCreative } = this.props.creatives;
    const { urlLoading, url, error } = this.state;

    let footer = <p className="text-muted">Please select segment values</p>;
    if (urlLoading) {
      footer = <div className="loader" />;
    } else if (error) {
      footer = <p className="text-danger">An error occured</p>;
    } else if (url) {
      footer = (
        <audio controls>
          <source type="audio/mpeg" src={this.state.url} />
        </audio>
      );
    }

    let dcoPreviewLink = "";
    let dcoValidatorLink = "";
    if (currentCreative) {
      dcoPreviewLink = `${DCO_PREVIEW_URL}?dcoId=${currentCreative.dcoValidatorId}`;
      dcoValidatorLink = `${DCO_VALIDATOR_URL}/${currentCreative.dcoValidatorId}`;
    }

    return (
      <div className="my-9 my-md-9 my-lg-9">
        <div className="container">
          <div className="page-header">
            <div className="card">
              <div className={`card-status bg-${this.getColorCard(currentCampaign)}`} />
              <div className="card-header" style={{ cursor: "pointer" }} onClick={this.onClickHeaderCampaign}>
                <h1 className="mt-3 mb-3">{currentCampaign && currentCampaign.name}</h1>
                <div className="card-options">
                  {currentCampaign && currentCampaign.active ? (
                    <Pause className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
                  ) : (
                    <Play className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
                  )}
                  <Link onClick={e => e.stopPropagation()} to={`/campaign/${currentCampaign && currentCampaign.id}/edit`} className="mt-1">
                    <EditIcon />
                  </Link>
                </div>
              </div>
            </div>
            <div className="card">
              <div className={`card-status bg-${currentAd && currentAd.active ? "green" : "yellow"}`} />
              <div className="card-header">
                <h2 className="mt-3 mb-3">{currentAd && currentAd.name}</h2>
                <div className="card-options">
                  {currentAd && currentAd.active ? (
                    <Pause className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
                  ) : (
                    <Play className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
                  )}
                  <Link
                    onClick={e => e.stopPropagation()}
                    to={`/campaign/${currentCampaign && currentCampaign.id}/ads/${currentAd && currentAd.id}/edit`}
                    className="mt-1"
                  >
                    <EditIcon />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <h2 className="mb-5">{"Preview"}</h2>
          <div className="container">
            {currentCreative && (
              <>
                <CardLink
                  id="preview"
                  title="DCO Preview"
                  subtitle={
                    <p className="mb-3">
                      Highligh your content effortlessly, <span className="font-weight-bold">use this link on the website</span>
                    </p>
                  }
                  link={dcoPreviewLink}
                  onCopy={this.onCopy}
                />
                <CardLink
                  id="validator"
                  title="DCO Validator"
                  subtitle={<p className="mb-3">Validate content accuracy through a quick preview</p>}
                  link={dcoValidatorLink}
                  onCopy={this.onCopy}
                />
              </>
            )}
            <div className="card">
              {currentCreative && currentCreative.companionBanner.banners && (
                <div className="card-body">
                  <Select
                    key="banner"
                    label="Banner"
                    options={currentCreative.companionBanner.banners.map(banner => ({ label: banner.url, value: banner.url }))}
                    onChange={this.onChangeBanner}
                    value={this.state.banner}
                  />
                  {this.state.banner && <img src={this.state.banner} alt="preview" />}
                </div>
              )}

              <div className="card-body">
                {currentCreative &&
                  currentCreative.segmentOrder.map((segmentType, index) => {
                    const segmentVariables = currentCreative.segments[index];
                    const options = segmentVariables.map(variable => {
                      if (segmentType === "poi" && typeof variable.value === "object") {
                        if (variable.value.label)
                          return {
                            label: (
                              <span>
                                {variable.value.label}{" "}
                                <span className="small text-monospace text-secondary">
                                  {`${variable.value.lat}, ${variable.value.lon} - ${variable.value.rad}`}
                                </span>
                              </span>
                            ),
                            value: variable.value.label,
                          };
                        return {
                          label: `${variable.value.lat}_${variable.value.lon}_${variable.value.rad}`,
                          value: `${variable.value.lat}_${variable.value.lon}_${variable.value.rad}`,
                        };
                      } else if (segmentType === "random") {
                        const randomLabel = `Random ${variable.value}`;
                        return { label: randomLabel, value: variable.value };
                      } else if (segmentType === "podcast") {
                        return { label: variable.display || "default", value: variable.display || variable.value };
                      }
                      return { label: variable.value, value: variable.value };
                    });
                    return (
                      <Select
                        key={segmentType}
                        label={segmentType}
                        options={options}
                        value={this.state.values[segmentType]}
                        onChange={this.onChange(segmentType)}
                      />
                    );
                  })}
              </div>
              <div className="card-footer d-flex">
                <div className="ml-5 mt2">{footer}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const CardLink = ({ id, title, subtitle, link, onCopy }) => {
  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex flex-column">
          <h3 className={`mt-3 ${subtitle ? "mb-0" : "mb-3"}`}>{title}</h3>
          {subtitle}
          <a href={link} target="_blank" rel="noopener noreferrer" className="mr-auto dco-link">
            {link}
          </a>
        </div>
        <textarea value={link} id={`copy-${id}`} readOnly className="sr-only" />
        <div className="card-options">
          <Copy className="mt-1 dco-copy-btn" id={`dco-copy-btn-${id}`} onClick={() => onCopy(id)} />
          <div id={`dco-copy-tooltip-${id}`} className="tooltip">
            Copy
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ creatives, ads, campaigns }) => ({ creatives, ads, campaigns }), mapDispatchToProps)(Traffic);
