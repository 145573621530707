import constants from "../../constants";
import * as modifiers from "./modifiers";
import exporter from "../exporter";

const initial = {
  isCreating: false,
  isFetchingAllCreatives: false,
  isGetting: false,
  all: [],
  current: null,
};

const handlers = {
  [constants.FETCH_CREATIVE]: modifiers.onFetchAllCreatives,
  [constants.FETCHING_CREATIVE]: modifiers.onFetchingAllCreatives,
  [constants.CREATING_CREATIVE]: modifiers.onCreatingCreative,
  [constants.GETTING_CREATIVE]: modifiers.onGettingCreative,
  [constants.GET_CREATIVE]: modifiers.onGetCreative,
  [constants.DELETE_CURRENT_CREATIVE]: modifiers.onDeleteCurrent,
  [constants.DELETE_ALL_AD]: modifiers.onDeleteAll,
};

export default exporter(initial, handlers);
