import React, { Component } from "react";
import { Input, Select } from "../../components";
import { Link } from "react-router-dom";
import platforms from "../../assets/platforms.json";
import ReactSelect from "react-select";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../utils";

function isPlatformAudiomaticOrMediamathOrZippycast(platform) {
  return ["audiomatic", "mediamath", "zippycast"].includes(platform);
}

function isPlatformDV360(platform) {
  return ["dv360"].includes(platform);
}

function isPlatformDisabled(platform) {
  const result = platforms.find(p => p.value === platform);
  return result != null ? result.isDisabled : false;
}

class PublisherLineForm extends Component {
  static displayName = "PublisherLineForm";
  constructor(props) {
    super(props);
    this.state = {
      isCustom: false,
      values: props.current
        ? {
            ...props.current,
          }
        : {
            name: "",
            platform: null,
            publisher: "",
            cpm: null,
            buyCpm: null,
            externalAdvertiserId: null,
            externalOrderId: null,
            externalEntityIds: [],
          },
    };
  }
  componentWillMount() {
    const { actions } = this.props;
    const { platform, externalOrderId, externalAdvertiserId } = this.state.values;

    if (platform && !isPlatformDisabled(platform)) {
      if (isPlatformDV360(platform)) {
        actions.platforms.fetchAllAdvertisers(platform);
        if (externalAdvertiserId) {
          actions.platforms.fetchAllOrders(platform, externalAdvertiserId);
        }
        if (externalOrderId) {
          actions.platforms.fetchEntities(platform, externalOrderId, externalAdvertiserId);
        }
      } else if (isPlatformAudiomaticOrMediamathOrZippycast(platform)) {
        actions.platforms.fetchAllOrders(platform);
        if (externalOrderId) {
          actions.platforms.fetchEntities(platform, externalOrderId);
        }
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { platform, externalOrderId, externalAdvertiserId } = this.state.values;
    const { actions, platforms } = this.props;

    const hasPlatformChanged = platform !== prevState.values.platform;
    const hasExternalAdvertiserIdChanged = prevState.values.externalAdvertiserId !== externalAdvertiserId;
    const hasExternalOrderIdChanged = prevState.values.externalOrderId !== externalOrderId;
    const hasEntitiesChanged = platforms.entities !== prevProps.platforms.entities;

    if (platform && !isPlatformDisabled(platform)) {
      if (isPlatformDV360(platform)) {
        if (hasPlatformChanged) {
          actions.platforms.fetchAllAdvertisers(platform);
        }
        if (hasExternalAdvertiserIdChanged && externalAdvertiserId) {
          actions.platforms.fetchAllOrders(platform, externalAdvertiserId);
        }
      } else if (isPlatformAudiomaticOrMediamathOrZippycast(platform)) {
        if (hasPlatformChanged) {
          actions.platforms.fetchAllOrders(platform);
        }
      }
      if (hasExternalOrderIdChanged && externalOrderId) {
        actions.platforms.fetchEntities(platform, externalOrderId, externalAdvertiserId);
      }
    }
    if (hasEntitiesChanged && platforms.entities) {
      actions.publisherLine.filterByExternalEntities(
        platforms.entities.map(({ id }) => id),
        platform,
      );
    }
  }

  canSubmit() {
    const { name, publisher, platform } = this.state.values;
    let canSubmit = false;
    const isNameNotEmpty = Boolean(name.trim());
    const isPublisherNotEmpty = Boolean(publisher.trim());
    const isPlatformNotEmpty = Boolean(typeof platform === "string" ? platform.trim() : platform);

    if (isNameNotEmpty && isPublisherNotEmpty && isPlatformNotEmpty) {
      canSubmit = true;
    }

    return canSubmit;
  }
  onChangeCustomInput = type => event => {
    this.setState({ values: { ...this.state.values, [type]: event } });
  };

  onChange = type => event => {
    if (event === "custom") {
      return this.setState({
        values: { ...this.state.values, platform: "", publisher: "" },
        isCustom: true,
      });
    }
    if (type === "platform") {
      const newValues = {
        values: {
          ...this.state.values,
          [type]: event,
          publisher: "",
          externalAdvertiserId: null,
          externalOrderId: null,
          externalEntityIds: [],
        },
        isCustom: false,
      };
      return this.setState(newValues);
    }
    if (type === "publisher") {
      const newValues = {
        values: {
          ...this.state.values,
          [type]: event,
          externalAdvertiserId: null,
          externalOrderId: null,
          externalEntityIds: [],
        },
        isCustom: false,
      };
      return this.setState(newValues);
    }
    if (type === "externalAdvertiserId") {
      const newValues = {
        values: {
          ...this.state.values,
          externalAdvertiserId: event,
          externalOrderId: null,
          externalEntityIds: [],
        },
      };
      return this.setState(newValues);
    }
    if (type === "cpm" || type === "buyCpm") {
      const newValues = {
        values: {
          ...this.state.values,
          [type]: event.target.value === "" ? null : event.target.value,
        },
      };
      return this.setState(newValues);
    }
    if (type === "order") {
      const newValues = {
        values: {
          ...this.state.values,
          externalOrderId: event,
          externalEntityIds: [],
        },
      };
      return this.setState(newValues);
    }
    if (type === "externalEntityIds") {
      const newValues = {
        values: { ...this.state.values, externalEntityIds: event },
      };
      return this.setState(newValues);
    }
    const newValues = {
      values: { ...this.state.values, [type]: event.target.value },
    };
    return this.setState(newValues);
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.values);
  };

  getPublishers = () => {
    const platform = platforms.find(platform => this.state.values.platform === platform.value);
    return (platform && platform.publishers) || [];
  };

  isCustomPlatform = currentPlatform => !platforms.some(platform => platform.value === currentPlatform);

  generateAdvertiserIdInput = () => {
    const { platform, externalAdvertiserId } = this.state.values;
    const {
      platforms: { advertisers, isFetchingAdvertisers },
    } = this.props;

    let placeholder;
    let inputLabel;
    switch (platform) {
      case "dv360":
        placeholder = "Choose your advertiser ...";
        inputLabel = "DV360 advertiser";
        break;
      default:
        throw new Error(`Unknown platform: ${platform}`);
    }

    const options = advertisers.sort((a, b) => b.id - a.id).map(({ id, name }) => ({ value: id, label: `${name} (${id})` }));

    const values = externalAdvertiserId
      ? [
          options.find(advertiser => advertiser.value === externalAdvertiserId) || {
            value: externalAdvertiserId,
            label: externalAdvertiserId,
          },
        ]
      : [];

    return isFetchingAdvertisers ? (
      <div className="dimmer active">
        <div className="loader" />
        <div className="dimmer-content">{"Loading"}</div>
      </div>
    ) : (
      <div className="form-group">
        <label className="form-label">{inputLabel}</label>
        <ReactSelect
          onChange={event => this.onChange("externalAdvertiserId")(event.value)}
          value={values}
          placeholder={placeholder}
          options={options}
        />
      </div>
    );
  };

  generateOrderIdInput = () => {
    const { platform, externalOrderId, externalAdvertiserId } = this.state.values;
    const {
      platforms: { orders, isFetchingOrders },
    } = this.props;

    let placeholder;
    let inputLabel;
    switch (platform) {
      case "audiomatic":
        placeholder = "Choose your order ...";
        inputLabel = "Audiomatic Order";
        break;
      case "mediamath":
        placeholder = "Choose your campaign ...";
        inputLabel = "Mediamath Campaign";
        break;
      case "zippycast":
        placeholder = "Choose your order ...";
        inputLabel = "Zippycast Order";
        break;
      case "dv360":
        placeholder = "Choose your campaign ...";
        inputLabel = "DV360 campaign";
        break;
      default:
        throw new Error(`Unknown platform: ${platform}`);
    }

    const options = orders.sort((a, b) => b.id - a.id).map(({ id, name }) => ({ value: id, label: `${name} (${id})` }));

    const values = externalOrderId
      ? [
          options.find(order => order.value === externalOrderId) || {
            value: externalOrderId,
            label: externalOrderId,
          },
        ]
      : [];

    return isFetchingOrders ? (
      <div className="dimmer active">
        <div className="loader" />
        <div className="dimmer-content">{"Loading"}</div>
      </div>
    ) : (
      <div className="form-group">
        <label className="form-label">{inputLabel}</label>
        <ReactSelect
          onChange={event => this.onChange("order")(event.value)}
          value={values}
          placeholder={placeholder}
          options={options}
          isDisabled={isPlatformDV360(platform) && !externalAdvertiserId}
        />
      </div>
    );
  };

  generateExternalEntityIdsInput = () => {
    const { values } = this.state;
    const { platforms, current, publisherLines } = this.props;

    let placeholder = "Choose your order first ...";
    let inputLabel;

    switch (values.platform) {
      case "audiomatic":
        inputLabel = "Audiomatic Campaigns";
        if (values.externalOrderId) placeholder = "Choose your campaigns ...";
        break;
      case "mediamath":
        inputLabel = "Mediamath Strategies";
        if (values.externalOrderId) placeholder = "Choose your strategies ...";
        break;
      case "zippycast":
        inputLabel = "Zippycast Campaigns";
        if (values.externalOrderId) placeholder = "Choose your campaigns ...";
        break;
      case "dv360":
        inputLabel = "DV360 Line Items";
        if (values.externalOrderId) placeholder = "Choose your line items ...";
        break;
      default:
        throw new Error(`Unknown platform: ${values.platform}`);
    }

    // We want to disable all this entities in the drop down select
    const disable = new Map(publisherLines.filteredEntities.map(entity => [entity.externalEntityId, entity]));
    // Using the disable map, we loop over the entities that we fetched to see if we have to disable them
    const options = platforms.entities.map(({ id, name }) => {
      let isDisabled = false;
      let label = `${name} (${id})`;

      const entity = disable.get(id);

      // If the entity exists in the disable map, we don't want to display the error label if:
      // - we are creating a publisher line
      // - we are editing the publisher line and the publisher line belongs to the entity
      if (entity && (!current || entity.publisherLineId !== current.id)) {
        isDisabled = true;
        label += `  (Already used in campaign "${entity.campaignName}", publisher line : "${entity.publisherLineName}")`;
      }
      return { value: id, label, isDisabled };
    });

    const items = [];
    values.externalEntityIds.forEach(entityId => {
      let notFound = true;
      for (let index = 0; index < options.length; index++) {
        const entity = options[index];
        if (entityId === entity.value) {
          items.push(entity);
          notFound = false;
          break;
        }
      }
      if (notFound) items.push({ value: entityId, label: entityId });
    });

    return platforms.isFetchingEntities ? (
      <div className="dimmer active">
        <div className="loader" />
        <div className="dimmer-content">{"Loading"}</div>
      </div>
    ) : (
      <div className="form-group">
        <label className="form-label">{inputLabel}</label>
        <ReactSelect
          isMulti
          isDisabled={!values.externalOrderId}
          onChange={event => this.onChange("externalEntityIds")(event ? event.map(({ value }) => value) : [])}
          value={items}
          placeholder={placeholder}
          options={options}
        />
      </div>
    );
  };

  render() {
    const { campaignId, current } = this.props;
    const { isCustom, values } = this.state;
    const title = current ? `Edit ${current.name}` : "Create Publisher Line";
    const isCustomPlatform = isCustom || (values.platform !== null && this.isCustomPlatform(values.platform));
    const canSubmit = this.canSubmit();
    const isPlatformDirect = values.platform === "direct";

    return (
      <div className="lg-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">{title}</h3>
          </div>
          <div className="card-body">
            <Input label={"Name"} id={"name"} isRequired type="text" value={values.name} onChange={this.onChange("name")} />
            <div className="platform-container">
              <Select
                isRequired
                label={"Platform"}
                options={[
                  ...platforms.map(p => ({
                    ...p,
                    disabled: p.isDisabled,
                  })),
                  { label: "Custom", value: "custom" },
                ]}
                onChange={this.onChange("platform")}
                value={isCustomPlatform ? "custom" : values.platform}
              />
              {isCustomPlatform && (
                <div className="form-group custom">
                  <input
                    className="selectize-input"
                    placeholder="Input your platform ..."
                    type="text"
                    style={{ cursor: "text", width: "100%" }}
                    onChange={event => this.onChangeCustomInput("platform")(event.target.value)}
                    value={values.platform}
                  />
                </div>
              )}
            </div>
            {!isCustomPlatform && (
              <Select
                isRequired
                label={"Publisher"}
                options={this.getPublishers()}
                onChange={this.onChange("publisher")}
                value={values.publisher}
                disabled={this.getPublishers().length === 0}
              />
            )}
            {isCustomPlatform && (
              <div className="form-group">
                <div className="form-label">
                  Publisher <span className="form-required">*</span>
                </div>
                <input
                  className="selectize-input"
                  placeholder="Input your publisher ..."
                  type="text"
                  style={{ cursor: "text", width: "100%" }}
                  onChange={event => this.onChangeCustomInput("publisher")(event.target.value)}
                  value={values.publisher}
                />
              </div>
            )}

            <Input label={"CPM"} id={"cpm"} type="number" value={values.cpm == null ? "" : values.cpm} onChange={this.onChange("cpm")} />

            {isPlatformDV360(values.platform) && this.generateAdvertiserIdInput()}

            {(isPlatformAudiomaticOrMediamathOrZippycast(values.platform) || isPlatformDV360(values.platform)) && (
              <>
                {this.generateOrderIdInput()}
                {this.generateExternalEntityIdsInput()}
              </>
            )}
            {isPlatformDirect && (
              <Input
                label={"Buy CPM"}
                id={"buyCpm"}
                type="number"
                value={values.buyCpm == null ? "" : values.buyCpm}
                onChange={this.onChange("buyCpm")}
              />
            )}
          </div>
          <div className="card-footer text-right">
            <div className="d-flex">
              <Link to={`/campaign/${campaignId}`} className="btn btn-link">
                {"Cancel"}
              </Link>
              <button disabled={!canSubmit} className={`btn btn-primary ml-auto`} onClick={this.onSubmit}>
                {"Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ platforms, publisherLines }) => ({ platforms, publisherLines }), mapDispatchToProps)(PublisherLineForm);
