import React, { Component } from "react";
import { Upload } from "react-feather";

export default class UploadButton extends Component {
  static displayName = "UploadButton";
  constructor(props) {
    super(props);
    this.state = {
      filename: null,
    };
  }
  onSetRef = node => {
    this.uploader = node;
  };
  onDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = "copy";
    return this.setState({ active: true });
  };
  onDragLeave = event => {
    event.preventDefault();
    event.stopPropagation();
    return this.setState({ active: false });
  };
  onDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    let files;
    if (event.dataTransfer.files) files = event.dataTransfer.files;
    else if (event.target) files = event.target.files;
    this.setState({ filename: files[0].name });
    return this.readCsv(files[0]);
  };
  readCsv = file => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = this.loadHandler;
    reader.onerror = this.errorHandler;
  };
  loadHandler = event => {
    const csv = event.target.result;
    const allTextLines = csv.split(/\r\n|\n/);
    const data = allTextLines
      .filter(line => line.length)
      .reduce(
        ({ headers, values }, line, index) => {
          if (!index) return { headers: line.split(";"), values: [] };
          const lineData = line.split(";");
          const objectLine = lineData.reduce((line, data, index) => {
            const header = headers[index];
            if (header) return { ...line, [header]: data };
            return line;
          }, {});
          return { headers, values: [...values, objectLine] };
        },
        { headers: null, values: [] },
      );
    return this.props.onChange(data.values);
  };
  handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    this.uploader.click();
  };
  onChange = event => {
    const { files } = event.target;
    this.setState({ filename: files[0].name });
    return this.readCsv(files[0]);
  };
  render() {
    return (
      <div onDragOver={this.onDragOver} onDragLeave={this.onDragLeave} onDrop={this.onDrop} className="d-flex align-items-center w-100">
        <button className="btn btn-secondary" style={{ minWidth: 50 }} onClick={this.handleClick}>
          <Upload className="mt-1" />
        </button>
        <input type="file" ref={this.onSetRef} onChange={this.onChange} accept=".csv" multiple style={{ display: "none" }} />
        {this.state.filename && <p className="pt-3 pl-2 w-100">{this.state.filename}</p>}
      </div>
    );
  }
}
