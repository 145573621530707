import React, { useEffect, useState } from "react";
import { AudioContext, initialData } from "./audioContext";

export const AudioProvider = ({ audios, children }) => {
  const [isPlaying, setIsPlaying] = useState(initialData.isPlaying);
  const [audioSrc, setAudioSrc] = useState(initialData.audiosSrc);
  const [audioList, setAudioList] = useState(initialData.audioList);
  const [rowIndex, setRowIndex] = useState(initialData.rowIndex);
  const [segmentIndex, setSegmentIndex] = useState(initialData.segmentIndex);

  useEffect(() => {
    if (audios !== null) {
      setAudioList(audios);
    }
  }, [audioList, audios]);

  return (
    <AudioContext.Provider
      value={{
        isPlaying,
        setIsPlaying,
        rowIndex,
        setRowIndex,
        segmentIndex,
        setSegmentIndex,
        audioSrc,
        setAudioSrc,
        audioList,
        setAudioList,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};
