import React, { Component } from "react";
import { Input } from "../../components";

export default class AdvertiserForm extends Component {
  static displayName = "AdvertiserForm";
  constructor(props) {
    super(props);
    this.state = {
      values: props.current
        ? {
            ...props.current,
          }
        : {
            name: "",
          },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.current) return this.setState({ values: nextProps.current });
  }
  onChange = type => event => {
    const newValues = {
      values: { ...this.state.values, [type]: event.target.value },
    };
    return this.setState(newValues);
  };
  onSubmit = () => {
    this.props.onSubmit(this.state.values);
  };
  onCancel = () => {
    this.props.onCancel();
  };
  render() {
    const title = this.props.current ? `Edit ${this.props.current.name}` : "Create advertiser";
    return (
      <div className="lg-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">{title}</h3>
          </div>
          <div className="card-body">
            <Input label={"Name"} id={"name"} isRequired type="text" value={this.state.values.name} onChange={this.onChange("name")} />
          </div>
          <div className="card-footer text-right">
            <div className="d-flex">
              <button type="button" className="btn btn-link" onClick={this.onCancel}>
                {"Cancel"}
              </button>
              <button onClick={this.onSubmit} className="btn btn-primary ml-auto">
                {"Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
