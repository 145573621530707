import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "./form";

import { mapDispatchToProps } from "../../utils";

class Edit extends Component {
  componentWillMount() {
    const {
      match: {
        params: { advertiserId },
      },
      actions,
    } = this.props;
    if (advertiserId !== "create") {
      actions.advertiser.get(advertiserId);
    }
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.advertiser.deleteCurrent();
  }
  onSubmit = values => {
    const { actions, history } = this.props;
    const { advertiserId } = this.props.match.params;
    const payload = { ...values };
    if (advertiserId !== "create") {
      return actions.advertiser.update(advertiserId, payload).then(() => history.push(`/advertiser`));
    }
    return actions.advertiser.create(payload).then(() => history.push(`/advertiser`));
  };
  onCancel = () => {
    const { history } = this.props;
    return history.push(`/advertiser`);
  };
  render() {
    const { campaignId } = this.props;
    if (this.props.advertisers.isGetting) return <p>{"Loader"}</p>;
    return (
      <div className="my-9 my-md-9">
        <div className="container">
          <Form current={this.props.advertisers.current} campaignId={campaignId} onSubmit={this.onSubmit} onCancel={this.onCancel} />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ user, routing, ads, advertisers }) => ({
    user,
    routing,
    ads,
    advertisers,
  }),
  mapDispatchToProps,
)(Edit);
