import constants from "../../constants";
import * as modifiers from "./modifiers";
import exporter from "../exporter";

const initial = {
  isCreating: false,
  isFetchingAllCampaigns: false,
  isGetting: false,
  all: [],
};

const handlers = {
  [constants.FETCH_ALL_CAMPAIGN]: modifiers.onFetchAllCampaigns,
  [constants.FETCHING_ALL_CAMPAIGNS]: modifiers.onFetchingAllCampaigns,
  [constants.CREATED_CAMPAIGN]: modifiers.onCreatedCampaign,
  [constants.CREATING_CAMPAIGN]: modifiers.onCreatingCampaign,
  [constants.GETTING_CAMPAIGN]: modifiers.onGettingCampaign,
  [constants.GET_CAMPAIGN]: modifiers.onGetCampaign,
  [constants.SEARCH_CAMPAIGNS]: modifiers.onSearchCampaigns,
  [constants.DELETE_ALL_CAMPAIGN]: modifiers.onDeleteAllCampaigns,
  [constants.DELETE_CURRENT_CAMPAIGN]: modifiers.onDeleteCurrentCampaign,
};

export default exporter(initial, handlers);
