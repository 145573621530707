const campaignConstants = {
  FETCH_ALL_CAMPAIGN: "FETCH_ALL_CAMPAIGN",
  FETCHING_ALL_CAMPAIGNS: "FETCHING_ALL_CAMPAIGNS",
  CREATED_CAMPAIGN: "CREATED_CAMPAIGN",
  CREATING_CAMPAIGN: "CREATING_CAMPAIGN",
  GETTING_CAMPAIGN: "GETTING_CAMPAIGN",
  GET_CAMPAIGN: "GET_CAMPAIGN",
  EDITING_CAMPAIGN: "EDITING_CAMPAIGN",
  DELETE_CURRENT_CAMPAIGN: "DELETE_CURRENT_CAMPAIGN",
  DELETE_ALL_CAMPAIGN: "DELETE_ALL_CAMPAIGN",
  SEARCH_CAMPAIGNS: "SEARCH_CAMPAIGNS",
};
const adConstants = {
  FETCH_ALL_ADS: "FETCH_ALL_ADS",
  FETCHING_ALL_ADS: "FETCHING_ALL_ADS",
  CREATED_AD: "CREATED_AD",
  CREATING_AD: "CREATING_AD",
  GETTING_AD: "GETTING_AD",
  GET_AD: "GET_AD",
  DELETE_CURRENT_AD: "DELETE_CURRENT_AD",
  DELETE_ALL_AD: "DELETE_ALL_AD",
  EDITING_AD: "EDITING_AD",
  DUPLICATING_AD: "DUPLICATING_AD",
  DUPLICATE_AD: "DUPLICATE_AD",
};

const assetConstants = {
  FETCHING_ASSETS: "FETCHING_ASSETS",
  FETCH_ASSETS: "FETCH_ASSETS",
  DELETE_CURRENT_ASSETS: "DELETE_CURRENT_ASSETS",
};

const creativeConstants = {
  FETCHING_CREATIVE: "FETCHING_CREATIVE",
  FETCH_CREATIVE: "FETCH_CREATIVE",
  CREATING_CREATIVE: "CREATING_CREATIVE",
  GETTING_CREATIVE: "GETTING_CREATIVE",
  EDITING_CREATIVE: "EDITING_CREATIVE",
  DELETE_CURRENT_CREATIVE: "DELETE_CURRENT_CREATIVE",
  DELETE_ALL_CREATIVE: "DELETE_ALL_CREATIVE",
  GET_CREATIVE: "GET_CREATIVE",
};

const advertiserConstants = {
  FETCHING_ALL_ADVERTISERS: "FETCHING_ALL_ADVERTISERS",
  FETCH_ALL_ADVERTISERS: "FETCH_ALL_ADVERTISERS",
  CREATING_ADVERTISER: "CREATING_ADVERTISER",
  CREATED_ADVERTISER: "CREATED_ADVERTISER",
  GETTING_ADVERTISER: "GETTING_ADVERTISER",
  DELETE_CURRENT_ADVERTISER: "DELETE_CURRENT_ADVERTISER",
  GET_ADVERTISER: "GET_ADVERTISER",
  EDITING_ADVERTISER: "EDITING_ADVERTISER",
};

const globalConstants = {
  TOGGLE_MENU: "TOGGLE_MENU",
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
  USER_CONNECTED: "USER_CONNECTED",
  DELETE_USER: "DELETE_USER",
  ON_LOGIN: "ON_LOGIN",
};

const metricsConstants = {
  METRICS_FETCHING: "METRICS_FETCHING",
  METRICS_FETCHED: "METRICS_FETCHED",
  DELETE_ALL_METRICS: "DELETE_ALL_METRICS",
};

const publisherLinesConstants = {
  FETCH_ALL_PUBLISHER_LINES: "FETCH_ALL_PUBLISHER_LINES",
  FETCHING_ALL_PUBLISHER_LINES: "FETCHING_ALL_PUBLISHER_LINES",
  CREATING_PUBLISHER_LINE: "CREATING_PUBLISHER_LINE",
  GETTING_PUBLISHER_LINE: "GETTING_PUBLISHER_LINE",
  GET_PUBLISHER_LINE: "GET_PUBLISHER_LINE",
  DELETE_CURRENT_PUBLISHER_LINE: "DELETE_CURRENT_PUBLISHER_LINE",
  DELETE_ALL_PUBLISHER_LINE: "DELETE_ALL_PUBLISHER_LINE",
  EDITING_PUBLISHER_LINE: "EDITING_PUBLISHER_LINE",
  DUPLICATING_PUBLISHER_LINE: "DUPLICATING_PUBLISHER_LINE",
  DUPLICATE_PUBLISHER_LINE: "DUPLICATE_PUBLISHER_LINE",
  FILTERING_EXTERNAL_ENTITIES: "FILTERING_EXTERNAL_ENTITIES",
  FILTER_EXTERNAL_ENTITIES: "FILTER_EXTERNAL_ENTITIES",
};

const platformConstants = {
  FETCH_ALL_ADVERTISERS: "FETCH_ALL_ADVERTISERS",
  FETCHING_ALL_ADVERTISERS: "FETCHING_ALL_ADVERTISERS",
  FETCH_ALL_ORDERS: "FETCH_ALL_ORDERS",
  FETCHING_ALL_ORDERS: "FETCHING_ALL_ORDERS",
  FETCH_ENTITIES: "FETCH_ENTITIES",
  FETCHING_ENTITIES: "FETCHING_ENTITIES",
};

const podcastConstants = {
  FETCHING_PODCAST: "FETCHING_PODCAST",
  FETCH_PODCAST: "FETCH_PODCAST",
};

export default {
  ...campaignConstants,
  ...adConstants,
  ...assetConstants,
  ...creativeConstants,
  ...advertiserConstants,
  ...globalConstants,
  ...metricsConstants,
  ...publisherLinesConstants,
  ...platformConstants,
  ...podcastConstants,
};
