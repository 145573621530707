import React, { Component, Fragment } from "react";
import { MetricsCard } from "../../components";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../utils";
import moment from "moment";

class Overview extends Component {
  static displayName = "Overview";
  componentWillMount() {
    const { actions } = this.props;
    const {
      campaigns: { current },
    } = this.props;
    actions.metrics.fetchMetrics(
      {
        campaigns: [this.props.campaignId],
        from: moment(current.start).valueOf(),
        to: moment(current.end).valueOf(),
      },
      "overview",
    );
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.metrics.deleteAll();
  }
  render() {
    const {
      metrics: {
        overview: { values: metrics, isFetching },
      },
    } = this.props;
    const campaignMetrics = metrics && metrics[this.props.campaignId];
    return (
      <Fragment>
        <h2 className="mb-5">{"Overview"}</h2>

        {campaignMetrics ? (
          <div className="row">
            {Object.keys(campaignMetrics).map(metricName => (
              <div className="col-md-6 col-xl-4" key={metricName}>
                <MetricsCard label={metricName} value={campaignMetrics[metricName]} isLoading={isFetching} />
              </div>
            ))}
          </div>
        ) : (
          <div className="container text-center mt-9">
            <h3 className="text-muted">{isFetching ? "Fetching metrics..." : "No metrics..."}</h3>
          </div>
        )}
      </Fragment>
    );
  }
}

export default connect(({ metrics, campaigns }) => ({ campaigns, metrics }), mapDispatchToProps)(Overview);
