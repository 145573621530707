export function onToggleMenu(state) {
  return { ...state, dropdownMenuCollapsed: !state.dropdownMenuCollapsed };
}

export function onShowModal(state, action) {
  return { ...state, showModal: true, modalProps: action.modalProps };
}

export function onHideModal(state) {
  return { ...state, showModal: false, modalProps: {} };
}

export function onGetUser(state, { user }) {
  return { ...state, user };
}

export function onDeleteUser(state) {
  return { ...state, isLogged: false, user: null };
}

export function onLogin(state) {
  return { ...state, isLogged: true };
}
