import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { ChevronUp, ChevronDown } from "react-feather";

import { Tab, MultiSelect, Input } from "../../components";
import { mapDispatchToProps } from "../../utils";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }
  getOptionsBanner = () => {
    const { all: allAssets } = this.props.assets;
    if (!allAssets) return [];
    return allAssets.images.map(asset => {
      return { label: asset.name, value: asset };
    });
  };
  onChange = type => event => {
    const { onChange } = this.props;
    if (type === "files") return onChange(type, event);
    onChange(type, event.target.value);
  };
  onCollapse = () => {
    return this.setState({ collapsed: !this.state.collapsed });
  };
  render() {
    const { values } = this.props;

    return (
      <Fragment>
        <div className={`card ${this.state.collapsed && "card-collapsed"} mt-6`}>
          <div className="card-header" onClick={this.onCollapse} style={{ cursor: "pointer" }}>
            <h3 className="card-title">{"Banner companion"}</h3>
            <div className="card-options">
              <a className={"mt-1"}>{!this.state.collapsed ? <ChevronUp /> : <ChevronDown />}</a>
            </div>
          </div>
          <div className="card-body">
            <MultiSelect label={"Files"} options={this.getOptionsBanner()} onChange={this.onChange("files")} values={values.files} />
            <Input label={"Click"} type={"text"} onChange={this.onChange("click")} value={values.click} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  ({ user, routing, campaigns, ads, assets }) => ({
    user,
    routing,
    campaigns,
    ads,
    assets,
  }),
  mapDispatchToProps,
)(Banner);
