import React, { Component } from "react";
import { Route } from "react-router";
import View from "./view";
import Edit from "./edit";

export default class Campaign extends Component {
  static displayName = "campaign";
  render() {
    return (
      <div>
        {/* <Route path={`{this.props.match.url}/create`} component={Edit}/> */}
        <Route path={`{this.props.match.url}/:id`} component={View} />
        <Route exact path={this.props.match.url} render={() => <h3>Please select a topic.</h3>} />
        {/* <Route exact path={'{this.props.match.url}/:id/edit'} component={Edit}/> */}
      </div>
    );
  }
}
