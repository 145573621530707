import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { ChevronUp, ChevronDown } from "react-feather";
import { Input } from "../../components/";
import { mapDispatchToProps } from "../../utils";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }
  onChange = type => event => {
    const { onChange } = this.props;
    onChange(type, event.target.value);
  };
  onCollapse = () => {
    return this.setState({ collapsed: !this.state.collapsed });
  };
  render() {
    const { values } = this.props;
    return (
      <Fragment>
        <div className={`card ${this.state.collapsed && "card-collapsed"} mt-6`}>
          <div className="card-header" onClick={this.onCollapse} style={{ cursor: "pointer" }}>
            <h3 className="card-title">{"Flight time"}</h3>
            <div className="card-options">
              <a className={"mt-1"}>{!this.state.collapsed ? <ChevronUp /> : <ChevronDown />}</a>
            </div>
          </div>
          <div className="card-body">
            <Input label={"Start Date"} id={"start"} isRequired type="date" value={values.start} onChange={this.onChange("start")} />

            <Input label={"End Date"} id={"end"} isRequired type="date" value={values.end} onChange={this.onChange("end")} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  ({ user, routing, campaigns, ads, assets }) => ({
    user,
    routing,
    campaigns,
    ads,
    assets,
  }),
  mapDispatchToProps,
)(Banner);
