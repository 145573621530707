import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Form from "./form";
import { Edit as EditIcon, Play, Pause } from "react-feather";
import moment from "moment";

import { mapDispatchToProps } from "../../utils";

class Edit extends Component {
  componentWillMount() {
    const { actions, campaignId, adId } = this.props;
    actions.ad.deleteCurrent();
    if (adId !== "create") {
      actions.ad.get(adId);
    }
    actions.campaign.get(campaignId);
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.ad.deleteCurrent();
  }
  onSubmit = values => {
    const {
      actions,
      history,
      campaignId,
      campaigns: { current },
      adId,
    } = this.props;
    const payload = {
      ...values,
      campaignId,
      advertiserId: current.advertiserId,
    };
    if (adId !== "create") {
      return actions.ad.update(adId, payload).then(() => history.push(`/campaign/${campaignId}`));
    }
    return actions.ad.create(payload).then(() => history.push(`/campaign/${campaignId}`));
  };
  onCancel = () => {
    const { campaignId, history } = this.props;
    return history.push(`/campaign/${campaignId}`);
  };
  onClickHeader = () => {
    const { history } = this.props;
    const { current: currentCampaign } = this.props.campaigns;
    return history.push(`/campaign/${currentCampaign && currentCampaign.id}`);
  };
  getColorCard = campaign => {
    if (!campaign) return;
    const now = moment();
    if (moment(campaign.start).isAfter(now)) {
      return "blue";
    }
    if (moment(campaign.start).isBefore(now) && moment(campaign.end).isAfter(now)) {
      if (campaign.active) return "green";
      return "yellow";
    }
    return "red";
  };
  onChangeState = event => {
    event.stopPropagation();
    const {
      actions,
      campaigns: { current },
    } = this.props;
    actions.campaign.update(current.id, { ...current, active: !current.active }).then(() => actions.campaign.get(current.id));
  };
  render() {
    const { campaignId } = this.props;
    const { current: currentCampaign } = this.props.campaigns;
    if (this.props.ads.isGetting) return <p>{"Loader"}</p>;
    return (
      <div className="my-9 my-md-9 my-lg-9">
        <div className="container">
          <div className="page-header">
            <div className="card">
              <div className={`card-status bg-${this.getColorCard(currentCampaign)}`} />
              <div className="card-header" style={{ cursor: "pointer" }} onClick={this.onClickHeader}>
                <h1 className="mb-3 mt-3">{currentCampaign && currentCampaign.name}</h1>
                <div className="card-options">
                  {currentCampaign && currentCampaign.active ? (
                    <Pause className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
                  ) : (
                    <Play className="mt-1" style={{ cursor: "pointer" }} onClick={this.onChangeState} />
                  )}
                  <Link onClick={e => e.stopPropagation()} to={`/campaign/${currentCampaign && currentCampaign.id}/edit`} className="mt-1">
                    <EditIcon />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Form current={this.props.ads.current} campaignId={campaignId} onSubmit={this.onSubmit} onCancel={this.onCancel} />
        </div>
      </div>
    );
  }
}

export default connect(({ user, routing, ads, campaigns }) => ({ user, routing, ads, campaigns }), mapDispatchToProps)(Edit);
