import { Input, Select, SelectPodcast } from "../../forms";
import UploadCsv from "../../uploadCSV";

const optionsDeviceOs = [
  { label: "Android", value: "android" },
  { label: "iOS", value: "ios" },
  { label: "Windows", value: "windows" },
  { label: "Mac", value: "mac" },
];

const optionsDeviceType = [
  { label: "Mobile", value: "mobile" },
  { label: "Tablet", value: "tablet" },
  { label: "Desktop", value: "desktop" },
];

const optionsDayOfWeek = [
  { label: "Sunday", value: "0" },
  { label: "Monday", value: "1" },
  { label: "Tuesday", value: "2" },
  { label: "Wednesday", value: "3" },
  { label: "Thursday", value: "4" },
  { label: "Friday", value: "5" },
  { label: "Saturday", value: "6" },
];

const optionsDay = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
];

const optionsHour = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
];

const optionsWeather = [
  { label: "Thunderstorm", value: "Thunderstorm" },
  { label: "Drizzle", value: "Drizzle" },
  { label: "Rain", value: "Rain" },
  { label: "Snow", value: "Snow" },
  { label: "Atmosphere", value: "Atmosphere" },
  { label: "Clear", value: "Clear" },
  { label: "Clouds", value: "Clouds" },
];

export const mappingSegment = {
  hour: {
    component: Select,
    props: values => {
      const options = optionsHour.filter(option => !values.includes(option.value));
      return { options, notInForm: true };
    },
    options: optionsHour,
  },
  dayOfWeek: {
    component: Select,
    props: values => {
      const options = optionsDayOfWeek.filter(option => !values.includes(option.value));
      return { options, notInForm: true };
    },
    options: optionsDayOfWeek,
  },
  day: {
    component: Select,
    props: values => {
      const options = optionsDay.filter(option => !values.includes(option.value));
      return { options, notInForm: true };
    },
    options: optionsDay,
  },
  deviceType: {
    component: Select,
    props: values => {
      const options = optionsDeviceType.filter(option => !values.includes(option.value));
      return { options, notInForm: true };
    },
    options: optionsDeviceType,
  },
  deviceOs: {
    component: Select,
    props: values => {
      const options = optionsDeviceOs.filter(option => !values.includes(option.value));
      return { options, notInForm: true };
    },
    options: optionsDeviceOs,
  },
  city: {
    component: Input,
    props: () => ({ notInForm: true }),
  },
  country: {
    component: Input,
    props: () => ({ notInForm: true }),
  },
  zipcode: {
    component: Input,
    props: () => ({ notInForm: true }),
  },
  poi: {
    component: UploadCsv,
    props: () => ({}),
  },
  weather: {
    component: Select,
    props: values => {
      const options = optionsWeather.filter(option => !values.includes(option.value));
      return { options, notInForm: true };
    },
  },
  podcast: {
    component: SelectPodcast,
    props: values => ({ values }),
    width: "500px",
  },
  random: {
    addOnly: true,
    props: values => ({ values, notInForm: true }),
  },
};
