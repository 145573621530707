export function onFetchAllCreatives(state, action) {
  return { ...state, all: action.creatives, isFetchingAllCreatives: false };
}

export function onFetchingAllCreatives(state, action) {
  return { ...state, isFetchingAllCreatives: true };
}

export function onCreatingCreative(state) {
  return { ...state, isCreating: true };
}

export function onGettingCreative(state) {
  return { ...state, isGetting: true };
}

export function onGetCreative(state, action) {
  return { ...state, current: action.creative, isGetting: false };
}
export function onDeleteCurrent(state, action) {
  return { ...state, current: null, isGetting: false };
}

export function onDeleteAll(state) {
  return { ...state, all: [] };
}
