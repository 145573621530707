import * as campaign from "./campaign";
import * as ad from "./ad";
import * as asset from "./asset";
import * as creative from "./creative";
import * as advertiser from "./advertiser";
import * as global from "./global";
import * as metrics from "./metrics";
import * as publisherLine from "./publisherLine";
import * as platforms from "./platforms";
import * as podcast from "./podcast";

export default {
  campaign,
  ad,
  asset,
  creative,
  advertiser,
  global,
  metrics,
  publisherLine,
  platforms,
  podcast,
};
