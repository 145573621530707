export function onMetricsFetching(state, { page }) {
  return { ...state, [page]: { isFetching: true } };
}

export function onMetricsFetched(state, { metrics, page }) {
  const metricsFormatted = metrics.reduce((acc, metric) => {
    const { campaign, ad, ...rest } = metric;
    if (!ad) {
      return { ...acc, [campaign]: { ...rest } };
    }
    return { ...acc, [campaign]: { ...acc[campaign], [ad]: { ...rest } } };
  }, {});
  return { ...state, [page]: { values: metricsFormatted, isFetching: false } };
}

export function onDeleteAllMetrics(state) {
  return { ...state, values: null };
}
