import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Plus } from "react-feather";
import moment from "moment";

import { mapDispatchToProps, getNormalizedString } from "../../utils";
import { CampaignCard, Tab } from "../../components";

const tabItems = [
  { label: "Running/Paused", key: "ongoing" },
  { label: "Ended", key: "finished" },
  { label: "Upcoming", key: "upcoming" },
];

const mappingColors = {
  ongoing: "green",
  upcoming: "blue",
  finished: "red",
};

class Home extends Component {
  static displayName = "Home";
  constructor(props) {
    super(props);
    this.state = {
      activeTab: localStorage.getItem("homeTab") || "ongoing",
      search: "",
      filteredCampaigns: props.campaigns.all,
    };
  }
  componentWillMount() {
    const { actions } = this.props;
    localStorage.removeItem("campaignTab");
    if (!localStorage.getItem("homeTab")) localStorage.setItem("homeTab", "ongoing");
    actions.campaign.fetchAll(localStorage.getItem("homeTab"));
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.metrics.deleteAll();
    actions.campaign.deleteAll();
    localStorage.removeItem("homeTab");
    this.setState({ filteredCampaigns: [] });
  }
  componentWillReceiveProps(next) {
    const {
      campaigns: { all: allNext, isFetchingAllCampaigns: isFetchingAllCampaignsNext },
    } = next;
    const {
      campaigns: { all },
      actions,
    } = this.props;
    if (all.length !== allNext.length && !isFetchingAllCampaignsNext && allNext.length > 0) {
      const campaigns = allNext.map(campaign => campaign.id);
      const flightTime = allNext.reduce(
        ({ from, to }, campaign) => {
          const timestampStart = moment(campaign.start).valueOf();
          const timestampEnd = moment(campaign.end).valueOf();
          if (timestampEnd > to || !to) to = timestampEnd;
          if (timestampStart < from || !from) from = timestampStart;
          return { from, to };
        },
        { from: 0, to: 0 },
      );
      this.setState({ filteredCampaigns: allNext });
      const payload = { campaigns, ...flightTime };
      return actions.metrics.fetchMetrics(payload, "home");
    }
  }
  onCreate = () => {
    const { history } = this.props;
    return history.push("/campaign/create");
  };
  onEditCampaign = campaignId => event => {
    event.stopPropagation();
    const { history } = this.props;
    return history.push(`/campaign/${campaignId}/edit`);
  };
  onChangeTab = tabKey => {
    const { actions } = this.props;
    localStorage.setItem("homeTab", tabKey);
    actions.campaign.fetchAll(tabKey).then(() => {
      this.setState({
        activeTab: tabKey,
        filteredCampaigns: this.props.campaigns.all.filter(campaign =>
          getNormalizedString(campaign.name).includes(getNormalizedString(this.state.search)),
        ),
      });
    });
  };
  onFilterName = () => event => {
    const name = event.target.value;
    this.setState({
      search: name,
      filteredCampaigns: this.props.campaigns.all.filter(campaign =>
        getNormalizedString(campaign.name).includes(getNormalizedString(name)),
      ),
    });
  };
  getCampaignColor = campaign => {
    if (this.state.activeTab === "ongoing" && !campaign.active) return "yellow";
    return mappingColors[this.state.activeTab];
  };
  handleCampaignUpdated = updatedCampaign => {
    const { actions } = this.props;
    actions.campaign.update(updatedCampaign.id, updatedCampaign).then(() => {
      actions.campaign.fetchAll(localStorage.getItem("homeTab"));
      this.setState({
        filteredCampaigns: this.state.filteredCampaigns.map(campaign => (campaign.id === updatedCampaign.id ? updatedCampaign : campaign)),
      });
    });
  };
  render() {
    const {
      campaigns: { all, isFetchingAllCampaigns },
      global: { dropdownMenuCollapsed },
      metrics: {
        home: { isFetching, values: metrics },
      },
    } = this.props;
    if (this.props.children) return this.props.children;
    return (
      <div>
        <div className={`header ${dropdownMenuCollapsed && "collapse"} d-lg-flex p-0 mt-8`}>
          <div className="container">
            <Tab items={tabItems} hasSearch activeTab={this.state.activeTab} onChange={this.onChangeTab} />
          </div>
        </div>
        <div className="my-9 my-md-9 my-lg-6">
          <div className="container">
            <div className="page-header d-flex justify-content-between">
              <div className="d-flex flex-row justify-content-between w-100" style={{ height: "40px" }}>
                <input
                  className="mr-2 form-control"
                  style={{ maxWidth: "350px" }}
                  id="name"
                  type="text"
                  placeholder="🔍  Search campaigns by name..."
                  value={this.state.search}
                  onChange={this.onFilterName()}
                />
                <Link to={"/campaign/create"} className="btn btn-secondary p-0 d-flex align-items-center justify-content-center">
                  <Plus className="p-0" />
                </Link>
              </div>
            </div>
            <div className="row" style={{ marginTop: "50px" }}>
              {all.length && this.state.filteredCampaigns.length ? (
                this.state.filteredCampaigns.map((campaign, key) => (
                  <div className="col-md-6 col-xl-4" key={key}>
                    <CampaignCard
                      campaign={campaign}
                      color={this.getCampaignColor(campaign)}
                      isMetricsFetching={isFetching}
                      metrics={metrics && metrics[campaign.id]}
                      onEdit={this.onEditCampaign(campaign.id)}
                      onCampaignUpdated={this.handleCampaignUpdated}
                    />
                  </div>
                ))
              ) : isFetchingAllCampaigns ? (
                <div>{"Fetching"}</div>
              ) : (
                <div>{"No Campaign"}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ user, routing, campaigns, global, metrics }) => ({
    user,
    routing,
    campaigns,
    global,
    metrics,
  }),
  mapDispatchToProps,
)(Home);
