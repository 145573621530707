export function onFetchAllCampaigns(state, action) {
  return {
    ...state,
    all: action.campaigns.sort((elmt1, elmt2) => {
      if (elmt1.name < elmt2.name) return -1;
      if (elmt1.name > elmt2.name) return 1;
      return 0;
    }),
    isFetchingAllCampaigns: false,
  };
}

export function onFetchingAllCampaigns(state, action) {
  return { ...state, isFetchingAllCampaigns: true };
}

export function onCreatingCampaign(state) {
  return { ...state, isCreating: true };
}

export function onCreatedCampaign(state) {
  return { ...state, isCreating: false };
}

export function onGettingCampaign(state) {
  return { ...state, isGetting: true };
}

export function onGetCampaign(state, action) {
  return { ...state, current: action.campaign, isGetting: false };
}

export function onDeleteCurrentCampaign(state, action) {
  return { ...state, current: null };
}

export function onSearchCampaigns(state, action) {
  return { ...state, campaignSearch: action.campaigns };
}
export function onDeleteAllCampaigns(state, action) {
  return { ...state, all: [] };
}
