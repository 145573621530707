import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { TopBar } from "../../components/";
import { mapDispatchToProps } from "../../utils";

class App extends Component {
  render() {
    return (
      <div className="page">
        <div className="page-main">
          {this.props.global.user && <TopBar user={this.props.global.user} />}
          {this.props.children}
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(
    ({ user, routing, campaigns, global }) => ({
      user,
      routing,
      campaigns,
      global,
    }),
    mapDispatchToProps,
  )(App),
);
