import React, { useRef } from "react";
import { Play, Pause } from "react-feather";

import { useAudioPlayer } from "../../hooks/useAudioPlayer";

export const AudioControls = ({ assetName, currentSegmentIndex, currentRowIndex }) => {
  const { audioSrc, setAudioSrc, audioList, isPlaying, setIsPlaying, segmentIndex, setSegmentIndex, rowIndex, setRowIndex } =
    useAudioPlayer();
  const publicUrlRef = useRef(null);
  const isDisabled = !assetName;
  const style = isDisabled ? { cursor: "not-allowed", pointerEvents: "revert" } : undefined;

  const currentAudioPlaying =
    isPlaying &&
    publicUrlRef.current &&
    publicUrlRef.current === audioSrc &&
    currentRowIndex === rowIndex &&
    currentSegmentIndex === segmentIndex;

  const onPause = () => {
    setAudioSrc("");
    setIsPlaying(false);
    setSegmentIndex(null);
    setRowIndex(null);
  };

  const onPlay = () => {
    const audio = audioList.find(({ name }) => name === assetName);
    if (audio !== undefined) {
      publicUrlRef.current = audio.publicUrl;
      setAudioSrc(audio.publicUrl);
      setIsPlaying(true);
      setSegmentIndex(currentSegmentIndex);
      setRowIndex(currentRowIndex);
    }
  };

  return (
    <button
      type="button"
      className="btn btn-link btn-sm d-flex justify-content-center align-items-center text-secondary"
      style={style}
      disabled={isDisabled}
      onClick={() => {
        currentAudioPlaying ? onPause() : onPlay();
      }}
    >
      {currentAudioPlaying ? <Pause /> : <Play />}
    </button>
  );
};
