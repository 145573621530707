import constants from "../../constants";
import * as modifiers from "./modifiers";
import exporter from "../exporter";

const initial = {
  isFetching: false,
  all: null,
};

const handlers = {
  [constants.FETCHING_ASSETS]: modifiers.onFetchingAssets,
  [constants.DELETE_CURRENT_ASSETS]: modifiers.onDelete,
  [constants.FETCH_ASSETS]: modifiers.onFetchAssets,
};

export default exporter(initial, handlers);
