import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Edit, Plus, Trash } from "react-feather";

import { Table } from "../../components";
import { mapDispatchToProps } from "../../utils";

class Advertiser extends Component {
  componentWillMount() {
    const { actions } = this.props;
    actions.advertiser.fetchAll();
  }
  getDataTable() {
    const {
      advertisers: { all: allAdvertisers = [] },
    } = this.props;
    return allAdvertisers.map(advertiser => {
      return [
        advertiser.name,
        advertiser.name,
        <Link to={`/advertiser/${advertiser.id}`}>
          <Edit className="w-4" />
        </Link>,
        <Trash className="w-4" />,
      ];
    });
  }
  render() {
    const {
      advertisers: { all: allAdvertisers = [] },
    } = this.props;
    return (
      <div className="my-9 my-md-9">
        <div className="container">
          <div className="row justify-content-between align-items-center mb-5 pr-3 pl-3">
            <h2 className="mb-5 mt-5">{"Advertisers"}</h2>
            <Link to={`${this.props.routing.location.pathname}/create`} className="btn btn-secondary ml-auto">
              <Plus className="mt-2" />
            </Link>
          </div>
          <div>
            {allAdvertisers.length > 0 ? (
              <div className="card">
                <Table data={this.getDataTable()} headers={["name", "", ""]} />
              </div>
            ) : (
              <h3 style={{ opacity: 0.6 }}>{"No data"}</h3>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ user, routing, advertisers }) => ({ user, routing, advertisers }), mapDispatchToProps)(Advertiser);
