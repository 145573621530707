import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { LogOut } from "react-feather";
import { withRouter } from "react-router";

import { mapDispatchToProps } from "../../utils";

class TopBar extends Component {
  static displayName = "TopBar";
  constructor(props) {
    super(props);
    this.state = {
      isUserDdOpen: false,
      isToggled: false,
    };
  }
  onClickUser = () => {
    const { isUserDdOpen } = this.state;
    return this.setState({ isUserDdOpen: !isUserDdOpen });
  };
  onDropdownClick = () => {
    const { actions } = this.props;
    actions.global.toggleMenu();
  };
  onToggle = () => {
    this.setState({ isToggled: !this.state.isToggled });
  };
  onLogout = () => {
    this.props.actions.global.onLogout(this.props.history);
  };
  render() {
    const isAdvertiserPage = this.props.routing.location.pathname.startsWith("/advertiser");
    const [image] = this.props.user.photos;
    const { isToggled } = this.state;
    return (
      <div className="header py-4" style={{ position: "fixed", top: 0, width: "100%", zIndex: 9999 }}>
        <div className="container">
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <Link className="header-brand" to="/">
              <img className="header-brand-img" src="https://storage.googleapis.com/audion-production-assets/logo/logo.png" alt="Audion" />
            </Link>
            <div className="d-flex order-lg-2 ml-auto nav-item">
              <div className="btn-group" role="group" aria-label="Basic outlined example">
                <Link className={`btn ${isAdvertiserPage ? "btn-outline-primary" : "btn-primary"}`} to={"/"}>
                  {"Campaigns"}
                </Link>
                <Link className={`btn ${isAdvertiserPage ? "btn-primary" : "btn-outline-primary"}`} to={"/advertiser"}>
                  {"Advertisers"}
                </Link>
              </div>
              <div className={`dropdown ${isToggled && "show"}`}>
                <a className="nav-link pr-0 leading-none" onClick={this.onToggle}>
                  <span className="avatar" style={{ backgroundImage: `url(${image.value})` }} />
                  <span className="ml-2 d-none d-lg-block">
                    <span className="text-default">{this.props.user.displayName}</span>
                    <small className="text-muted d-block mt-1">{this.props.user.email}</small>
                  </span>
                </a>
                <div
                  className={`dropdown-menu dropdown-menu-right dropdown-menu-arrow ${isToggled && "show"}`}
                  style={{
                    position: "absolute",
                    transform: "translate3d(-56px, 32px, 0px)",
                    top: "0px",
                    left: "0px",
                    willChange: "transform",
                  }}
                >
                  <a className="dropdown-item" style={{ cursor: "pointer" }} onClick={this.onLogout}>
                    <LogOut className="mt-2" style={{ width: "14px", height: "14px" }} />
                    <span className="ml-3">{"Sign out"}</span>
                  </a>
                </div>
              </div>
            </div>
            <a className="header-toggler d-lg-none ml-3 ml-lg-0" style={{ cursor: "pointer" }} onClick={this.onDropdownClick}>
              <span className="header-toggler-icon" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(({ routing }) => ({ routing }), mapDispatchToProps)(TopBar));
