/* global localStorage */

import constants from "../../constants";
import { handleErrors } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export function toggleMenu() {
  return { type: constants.TOGGLE_MENU };
}

export function showModal({ modalProps }) {
  return { type: constants.SHOW_MODAL, modalProps };
}

export function hideModal() {
  return { type: constants.HIDE_MODAL };
}

export function getUser(history) {
  return dispatch => {
    return fetch(`${API_URL}/profile`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(user => dispatch({ type: constants.USER_CONNECTED, user }))
      .catch(() => history.push("/login"));
  };
}

export function deleteUser() {
  return { type: constants.DELETE_USER };
}

export function onLoggin() {
  return { type: constants.ON_LOGIN };
}

export function onLogout(history) {
  return dispatch => {
    return fetch(`${API_URL}/logout`, { credentials: "include" }).then(() => {
      dispatch({ type: constants.DELETE_USER });
      return history.push("/login");
    });
  };
}
