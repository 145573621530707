import constants from "../../constants";
import * as modifiers from "./modifiers";
import exporter from "../exporter";

const initial = {
  isCreating: false,
  isFetchingAllAds: false,
  isGetting: false,
  all: [],
};

const handlers = {
  [constants.FETCHING_ALL_ADVERTISERS]: modifiers.onFetchingAllAdvertisers,
  [constants.FETCH_ALL_ADVERTISERS]: modifiers.onFetchAllAdvertisers,
  [constants.CREATED_ADVERTISER]: modifiers.onCreatedAdvertiser,
  [constants.CREATING_ADVERTISER]: modifiers.onCreatingAdvertiser,
  [constants.GETTING_ADVERTISER]: modifiers.onGettingAdvertiser,
  [constants.GET_ADVERTISER]: modifiers.onGetAdvertiser,
  [constants.DELETE_CURRENT_ADVERTISER]: modifiers.onDeleteCurrent,
};

export default exporter(initial, handlers);
