import React, { Component } from "react";

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxes: {},
      sizesChecked: [],
    };
  }

  onChange = event => {
    const label = event.target.value;
    const { checkboxes, sizesChecked } = this.state;
    const newSizesChecked = [...sizesChecked];
    const size = this.convertSizeToString(checkboxes[label].value);
    if (checkboxes[label].checked) {
      const index = sizesChecked.indexOf(size);
      if (index > -1) newSizesChecked.splice(index, 1);
    } else {
      newSizesChecked.push(size);
    }
    const checkboxesUpdated = {
      ...checkboxes,
      [label]: { ...checkboxes[label], checked: !checkboxes[label].checked },
    };
    this.setState({
      checkboxes: checkboxesUpdated,
      sizesChecked: newSizesChecked,
    });
    const selectedFiles = [];
    Object.entries(checkboxesUpdated).forEach(([key, value]) => {
      if (value.checked) selectedFiles.push(key);
    });
    this.props.onChange(selectedFiles);
  };
  componentWillMount() {
    const { options, values } = this.props;
    const { sizesChecked } = this.state;
    let checkboxes = {};
    options.forEach(option => {
      if (values.includes(option.label)) {
        const size = this.convertSizeToString(option.value);
        sizesChecked.push(size);
      }
      checkboxes = {
        ...checkboxes,
        [option.label]: {
          checked: values.includes(option.label),
          value: option.value,
        },
      };
    });
    this.setState({ checkboxes });
  }
  convertSizeToString = value => `${value.width}x${value.height}`;

  render() {
    const { checkboxes, sizesChecked } = this.state;

    const selectComponent = (
      <div>
        {Object.entries(checkboxes).map(([label, value]) => {
          const size = this.convertSizeToString(value.value);
          const isEnabled = value.checked || !sizesChecked.includes(size);
          const style = {};
          if (!isEnabled) {
            style.color = "#ced3d6";
          }
          return (
            <div key={label} value={label} style={{ ...style }}>
              <input
                type="checkbox"
                name={label}
                id={label}
                readOnly={!isEnabled}
                onChange={isEnabled ? this.onChange : undefined}
                checked={value.checked}
                value={label}
              />
              <label htmlFor={label} style={{ paddingLeft: "6px" }}>
                {" "}
                {label} | {size}
              </label>
            </div>
          );
        })}
      </div>
    );
    if (this.props.notInForm) return selectComponent;
    return (
      <div className="form-group">
        <label htmlFor={this.props.id} className="form-label">
          {this.props.label}
          {this.props.isRequired && <span className="form-required">{"*"}</span>}
        </label>
        {selectComponent}
      </div>
    );
  }
}

export default MultiSelect;
