import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Select from "./select";
import { mapDispatchToProps } from "../../utils";

class Search extends Component {
  onChangeInput = value => {
    const { actions } = this.props;
    actions.campaign.search(value);
  };
  getIndicator = campaign => {
    const now = moment();
    if (moment(campaign.start).isAfter(now)) {
      return "#5689d2";
    }
    if (moment(campaign.start).isBefore(now) && moment(campaign.end).isAfter(now)) {
      if (campaign.active) return "#5eba00";
      return "#f1c40f";
    }
    return "#cd201f";
  };
  getOptions = () => {
    const {
      campaigns: { campaignSearch },
    } = this.props;
    if (!campaignSearch) return [];
    return campaignSearch.map(campaign => ({
      label: campaign.name,
      value: campaign.id,
      indicator: this.getIndicator(campaign),
    }));
  };
  onClickOption = value => {
    this.props.history.push(`/campaign/${value}`);
  };
  render() {
    return (
      <Select placeholder={"Search..."} notInForm onSearch={this.onChangeInput} options={this.getOptions()} onChange={this.onClickOption} />
    );
  }
}

export default connect(({ campaigns }) => ({ campaigns }), mapDispatchToProps)(Search);
