import constants from "../../constants";
import * as modifiers from "./modifiers";
import exporter from "../exporter";

const initial = {
  isFetchingAdvertisers: false,
  advertisers: [],
  isFetchingOrders: false,
  orders: [],
  isFetchingEntities: false,
  entities: [],
};

const handlers = {
  [constants.FETCH_ALL_ADVERTISERS]: modifiers.onFetchAllAdvertisers,
  [constants.FETCHING_ALL_ADVERTISERS]: modifiers.onFetchingAllAdvertisers,
  [constants.FETCH_ALL_ORDERS]: modifiers.onFetchAllOrders,
  [constants.FETCHING_ALL_ORDERS]: modifiers.onFetchingAllOrders,
  [constants.FETCH_ENTITIES]: modifiers.onFetchEntities,
  [constants.FETCHING_ENTITIES]: modifiers.onFetchingEntities,
};

export default exporter(initial, handlers);
