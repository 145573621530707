export function onFetchAllAds(state, action) {
  return {
    ...state,
    all: action.ads.sort((elmt1, elmt2) => {
      if (elmt1.name < elmt2.name) return -1;
      if (elmt1.name > elmt2.name) return 1;
      return 0;
    }),
    isFetchingAllAds: false,
  };
}

export function onFetchingAllAds(state, action) {
  return { ...state, isFetchingAllAds: true };
}

export function onCreatingAd(state) {
  return { ...state, isCreating: true };
}

export function onCreatedAd(state) {
  return { ...state, isCreating: false };
}

export function onGettingAd(state) {
  return { ...state, isGetting: true };
}

export function onGetAd(state, action) {
  return { ...state, current: action.ad, isGetting: false };
}

export function onDeleteCurrentAd(state) {
  return { ...state, current: null };
}
export function onDeleteAllAds(state) {
  return { ...state, all: [] };
}

export function onDuplicatingAd(state) {
  return { ...state };
}
export function onDuplicateAd(state) {
  return { ...state };
}
