import React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../utils";

const Modal = props => {
  function closeModal() {
    props.actions.global.hideModal();
  }

  function validateModal() {
    props.validate();
    closeModal();
  }

  return props.show ? (
    <>
      <div className="modal fade show" style={{ display: "block", zIndex: 1010 }} onClick={closeModal} />
      <div
        className="modal-dialog modal-dialog-centered"
        style={{
          position: "absolute",
          zIndex: 1020,
          top: 0,
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <div className="modal-content">
          {props.head ? <div className="modal-header">{props.head}</div> : null}
          {!props.children && props.body && <div className="modal-body" dangerouslySetInnerHTML={{ __html: props.body }} />}
          {props.children && !props.body && <div className="modal-body">{props.children}</div>}
          {!props.hideFooter && (
            <div className="modal-footer">
              <button onClick={closeModal} className="btn btn-secondary">
                Cancel
              </button>
              <button onClick={validateModal} className="btn btn-primary">
                OK
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={`modal-backdrop fade show`} style={{ zIndex: 1000 }} onClick={closeModal} />
    </>
  ) : null;
};

export default connect(() => ({}), mapDispatchToProps)(Modal);
