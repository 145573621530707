import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Plus } from "react-feather";
import moment from "moment";

import { mapDispatchToProps } from "../../utils";
import { AdCard } from "../../components";

class Ads extends Component {
  static displayName = "Ads";
  componentDidMount() {
    const {
      campaigns: { current },
      ads: { all: allAds },
    } = this.props;
    this.props.actions.ad.fetchAll(current.id);
    this.props.actions.creative.fetchAll(current.id);
    if (allAds.length) return this.fetchMetrics(current, allAds);
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.metrics.deleteAll();
    actions.ad.deleteAll();
    actions.creative.deleteAll();
  }
  componentWillReceiveProps(next) {
    const {
      ads: { all: allNext, isFetchingAllAds: isFetchingAllAdsNext },
    } = next;
    const {
      ads: { all },
      campaigns: { current },
    } = this.props;
    if (all.length !== allNext.length && !isFetchingAllAdsNext && allNext.length > 0) {
      this.fetchMetrics(current, allNext);
    }
  }
  fetchMetrics = (campaign, ads) => {
    const { actions } = this.props;
    const campaignId = campaign.id;
    const flightTime = {
      from: moment(campaign.start).valueOf(),
      to: moment(campaign.to).valueOf(),
    };
    const adsIds = ads.map(ad => ad.id);
    const payload = { campaigns: [campaignId], ...flightTime, ads: adsIds };
    return actions.metrics.fetchMetrics(payload, "ads");
  };
  onCreate = () => {
    this.props.onCreate();
  };
  onDuplicate = (...args) => {
    this.props.onDuplicate(...args);
  };
  render() {
    const {
      ads: { all: allAds },
      campaigns: { current },
      creatives: { all: allCreatives },
      metrics: {
        ads: { values: metrics, isFetching },
      },
    } = this.props;
    return (
      <Fragment>
        <div className="row justify-content-between align-items-center mb-5 pr-3 pl-3">
          <h2 className="mb-5">{"Ads"}</h2>
          <Link to={`${this.props.routing.location.pathname}/ads/create`} className="btn btn-secondary ml-auto">
            <Plus className="mt-2" />
          </Link>
        </div>
        <div className="row">
          {allAds.map(ad => {
            const creative = allCreatives.find(creative => creative.adId === ad.id);
            return (
              <div className="col-lg-12" key={ad.id}>
                <AdCard
                  ad={ad}
                  onDuplicate={this.onDuplicate}
                  creative={creative}
                  metrics={metrics && metrics[current.id] && metrics[current.id][ad.id]}
                  isMetricsFetching={isFetching}
                />
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  }
}

export default connect(
  ({ user, routing, ads, campaigns, creatives, metrics }) => ({
    user,
    routing,
    ads,
    campaigns,
    creatives,
    metrics,
  }),
  mapDispatchToProps,
)(Ads);
