import React, { useCallback, useEffect, memo } from "react";
import { Check, X } from "react-feather";

const RadioPOI = memo(function RadioPOI({
  variable,
  options,
  search,
  selectedAssets,
  setSelectedAssets,
  onChange,
  onCheck,
  onCancelMatch,
}) {
  const location = variable.value.label;
  const currentSelectedAsset = selectedAssets.find(asset => asset.value === variable.value);
  const currentAsset = currentSelectedAsset && currentSelectedAsset.name;
  const isCheckDisabled = currentAsset === variable.name;
  const handleCheck = useCallback(() => {
    onCheck(currentAsset);
  }, [currentAsset, onCheck]);
  const handleSwitchToSelect = useCallback(() => {
    onChange(null);
    onCancelMatch();
  }, [onChange, onCancelMatch]);
  const handleAssetSelection = useCallback(
    newValue => {
      setSelectedAssets(prev => {
        const filtered = prev.filter(asset => asset.value !== variable.value);
        return [...filtered, { ...variable, name: newValue }];
      });
    },
    [setSelectedAssets, variable],
  );

  useEffect(() => {
    if (search.length > 0 && !currentSelectedAsset) {
      setSelectedAssets(prevSelectedAssets => [...prevSelectedAssets, { ...variable, name: search[0].item.value }]);
    }
  }, [currentSelectedAsset, search, setSelectedAssets, variable]);

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex flex-column w-100 justify-content-start align-items-start">
        {search.map(({ item }, index) => {
          const existingAsset = selectedAssets.find(asset => asset.value === variable.value);
          const isCurrentSelected = existingAsset && existingAsset.name === item.value;
          const checked = isCurrentSelected || (!existingAsset && index === 0);
          return (
            <label key={`radio-${location}-${item.value}`} className={"d-flex justify-content-start align-items-center"}>
              <input type="radio" value={item.value} checked={checked} onChange={() => handleAssetSelection(item.value)} />
              <span className="mx-2 small text-truncate" style={{ maxWidth: "400px" }}>
                {options.find(option => option.value === item.value).label}
              </span>
            </label>
          );
        })}
      </div>
      <div className="mx-2 d-flex align-items-center justify-content-center" style={{ minWidth: "80px" }}>
        <button
          className="p-0 m-0 mr-2 btn btn-sm btn-primary d-flex align-items-center justify-content-center"
          style={{ cursor: isCheckDisabled ? "not-allowed" : "" }}
          onClick={handleCheck}
          disabled={isCheckDisabled}
        >
          <Check className="w-4" />
        </button>
        <button
          className="p-0 m-0 mr-2 btn btn-sm btn-outline-danger d-flex align-items-center justify-content-center"
          onClick={handleSwitchToSelect}
        >
          <X className="w-4" />
        </button>
      </div>
    </div>
  );
});

export default RadioPOI;
