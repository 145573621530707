/* global localStorage */

import constants from "../../constants";
import { handleErrors } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export function fetchAll(adId) {
  return dispatch => {
    dispatch({ type: constants.FETCHING_CREATIVE });
    const params = new URLSearchParams({
      campaignId: adId,
      includeDraftVariables: true,
    });
    return fetch(`${API_URL}/creatives?${params}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => handleErrors(response, dispatch))
      .then(response => response.json())
      .then(creatives => dispatch({ type: constants.FETCH_CREATIVE, creatives }))
      .catch(err => console.error(err));
  };
}

export function create(values) {
  return dispatch => {
    dispatch({ type: constants.CREATING_CREATIVE });
    return fetch(`${API_URL}/creatives`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify(values),
    }).then(response => handleErrors(response, dispatch));
  };
}

export function get(id) {
  return dispatch => {
    dispatch({ type: constants.GETTING_CREATIVE });
    const params = new URLSearchParams({
      includeDraftVariables: true,
    });
    const promises = [`${API_URL}/creatives/${id}?${params}`, `${API_URL}/creatives/dcolink/${id}`].map(url =>
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      })
        .then(response => handleErrors(response, dispatch))
        .then(response => response.json()),
    );
    Promise.all(promises)
      .then(([creative, { dcoId: dcoValidatorId }]) =>
        dispatch({
          type: constants.GET_CREATIVE,
          creative: { ...creative[0], dcoValidatorId },
        }),
      )
      .catch(err => console.error(err));
  };
}

export function update(id, values) {
  return dispatch => {
    dispatch({ type: constants.EDITING_CREATIVE });
    return fetch(`${API_URL}/creatives/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "PUT",
      body: JSON.stringify(values),
    }).then(response => handleErrors(response, dispatch));
  };
}

export function deleteCurrent() {
  return { type: constants.DELETE_CURRENT_CREATIVE };
}

export function deleteAll() {
  return { type: constants.DELETE_ALL_CREATIVE };
}
