import React, { Component } from "react";
import { css } from "aphrodite";

import style from "./style";

export default class Sidebar extends Component {
  static displayName = "Sidebar";
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  onOpen = () => {
    return this.setState({ open: true });
  };
  onClose = () => {
    return this.setState({ open: false });
  };
  render() {
    const { open } = this.state;
    return (
      <div>
        {open && <div className={css(style.overlay)} onClick={this.onClose} />}
        <div className={css(style.container, open ? style.open : style.close)}>
          <div className={css(style.header)}>
            <div className={css(style.headerLogoTitleContainer, open && style.headerLogoOpen)}>
              <img
                src={"https://www.offremedia.com/sites/default/files/nl1745-logo-audion.png"}
                className={css(style.headerLogo)}
                alt={"Logo"}
              />
              <p className={css(style.headerTitle)}>{"Audion"}</p>
            </div>

            <div onClick={open ? this.onClose : this.onOpen} className={css(style.bars)}>
              <span className={css(style.bar, style.topBar, open && style.topBarOpen)} />
              <span className={css(style.bar, style.middleBar, open && style.middleBarOpen)} />
              <span className={css(style.bar, style.bottomBar, open && style.bottomBarOpen)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
