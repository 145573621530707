/* global localStorage */

import constants from "../../constants";
import { handleErrors } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export const upload = (body, campaignId) => dispatch => {
  const formData = new FormData();
  Array.from(body).forEach(file => {
    formData.append("file", file);
  });
  return fetch(`${API_URL}/assets/upload/${campaignId}`, {
    method: "POST",
    credentials: "include",
    body: formData,
  })
    .then(response => handleErrors(response, dispatch))
    .then(() => listAsset(campaignId, dispatch))
    .catch(err => console.error(err));
};

export const list = campaignId => dispatch => {
  dispatch({ type: constants.FETCHING_ASSETS });
  return listAsset(campaignId, dispatch);
};

const listAsset = (campaignId, dispatch) => {
  return fetch(`${API_URL}/assets/${campaignId}`, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  })
    .then(response => handleErrors(response, dispatch))
    .then(response => response.json())
    .then(assets => dispatch({ type: constants.FETCH_ASSETS, assets }))
    .catch(err => console.error(err));
};

export const deleteCurrent = () => ({ type: constants.DELETE_CURRENT_ASSETS });
