import React from "react";

const Toggle = props => (
  <div className="form-group">
    <label className="custom-switch">
      <input
        type="checkbox"
        onChange={props.onChange}
        checked={props.value}
        className="custom-switch-input"
        name="custom-switch-checkbox"
      />
      <span className="custom-switch-indicator" />
      <span className="custom-switch-description">{props.label}</span>
    </label>
  </div>
);

export default Toggle;
