import React, { Component } from "react";
import { Input, Toggle, Select, Modal } from "../../components";
import moment from "moment";
import AdvertiserForm from "../advertiser/form";

export default class CampaignForm extends Component {
  static displayName = "CampaignForm";
  constructor(props) {
    super(props);
    this.state = {
      values: props.current
        ? {
            ...props.current,
            end: moment(props.current.end).format("YYYY-MM-DD"),
            start: moment(props.current.start).format("YYYY-MM-DD"),
          }
        : {
            name: "",
            active: false,
            advertiserId: "",
            start: "",
            end: "",
          },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.current) {
      return this.setState({
        values: {
          name: "",
          active: false,
          advertiserId: "",
          start: "",
          end: "",
        },
      });
    }
    if (nextProps.current)
      return this.setState({
        values: {
          ...nextProps.current,
          end: moment(nextProps.current.end).format("YYYY-MM-DD"),
          start: moment(nextProps.current.start).format("YYYY-MM-DD"),
        },
      });
  }
  formatValues = value => {};
  onChange = type => value => {
    if (type === "active") {
      return this.setState({
        values: { ...this.state.values, active: !this.state.values.active },
      });
    }
    if (type === "advertiserId") {
      return this.setState({
        values: { ...this.state.values, advertiserId: value },
      });
    }
    const newValues = {
      values: { ...this.state.values, [type]: value.target.value },
    };
    return this.setState(newValues);
  };
  onSubmit = () => {
    this.props.onSubmit(this.state.values);
  };
  onAdvertiserSubmit = values => {
    this.props.onAdvertiserSubmit(values);
  };
  onAdvertiserCancel = () => {
    this.props.onAdvertiserCancel();
  };
  getOptions = () => {
    const { advertisers } = this.props;
    return advertisers.map(advertiser => ({
      label: advertiser.name,
      value: advertiser.id,
    }));
  };
  onCancel = () => {
    this.props.onCancel();
  };
  canSubmit = () => {
    const { name, advertiserId, start, end } = this.state.values;
    let canSubmit = false;
    const isNameNotEmpty = Boolean(name.trim());
    const isAdvertiserNotEmpty = Boolean(advertiserId);
    const isStartDateNotEmpty = Boolean(start);
    const isEndDateNotEmpty = Boolean(end);

    if (isNameNotEmpty && isAdvertiserNotEmpty && isStartDateNotEmpty && isEndDateNotEmpty) {
      canSubmit = true;
    }

    return canSubmit;
  };
  handleCreateAdvertiser = _ => {
    this.props.actions.global.showModal({
      modalProps: {
        children: <AdvertiserForm onSubmit={this.onAdvertiserSubmit} onCancel={this.onAdvertiserCancel} />,
        hideFooter: true,
      },
    });
  };
  render() {
    const { showModal, modalProps } = this.props.global;
    const title = this.props.current ? `Edit ${this.props.current.name}` : "Create Campaign";
    const canSubmit = this.canSubmit();

    return (
      <div className="lg-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">{title}</h3>
          </div>
          <div className="card-body">
            <Input label={"Name"} id={"name"} isRequired type="text" value={this.state.values.name} onChange={this.onChange("name")} />
            <Toggle onChange={this.onChange("active")} value={this.state.values.active} label={"Active"} />

            <div className="position-relative">
              <button
                type="button"
                className="btn btn-sm btn-outline-primary position-absolute"
                style={{ right: 0, bottom: "44px" }}
                onClick={this.handleCreateAdvertiser}
              >
                Create advertiser
              </button>

              <Select
                label={"Advertiser"}
                isRequired
                onChange={this.onChange("advertiserId")}
                value={this.state.values.advertiserId}
                options={this.getOptions()}
              />
            </div>
            <Input
              label={"Start Date"}
              id={"start"}
              isRequired
              type="date"
              value={this.state.values.start}
              onChange={this.onChange("start")}
            />

            <Input label={"End Date"} id={"end"} isRequired type="date" value={this.state.values.end} onChange={this.onChange("end")} />
          </div>
          <div className="card-footer text-right">
            <div className="d-flex">
              <button onClick={this.onCancel} className="btn btn-link">
                {"Cancel"}
              </button>
              <button onClick={this.onSubmit} disabled={!canSubmit} className="btn btn-primary ml-auto">
                {"Submit"}
              </button>
            </div>
          </div>
        </div>
        <Modal {...modalProps} show={showModal} />
      </div>
    );
  }
}
